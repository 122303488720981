import * as React from "react";
import { InteractionObjectLayout, dateRegex, guidRegex } from "../../constants";
// Features
import { InteractionState } from "features/interaction/mappers";
// Components
import {
    Form, FormErrorObject
} from "containers/Form"
import { FormField } from "components/Elements";

interface Props {
    initialState: InteractionState,
    isEdit: boolean,
    isModal: boolean,
    onClickExitHandler: React.MouseEventHandler,
    onClickSaveHandler: (formData: InteractionState) => void
}

const InteractionForm: React.FC<Props> = ({ initialState, isEdit, isModal, onClickExitHandler, onClickSaveHandler }) => {
    const validateInteractionForm = (formData: { [key: string]: any }): FormErrorObject => {
        let errorObject: FormErrorObject = {
            error: false,
            message: null
        }
		if (formData.hasOwnProperty("contactId")) {
			if (!new RegExp(guidRegex).test(formData.contactId)) {
				errorObject.error = true;
				errorObject.message = "Please enter a valid contact ID.";
				return errorObject
			}
		} 
		if (!new RegExp(dateRegex).test(formData.date)) {
            errorObject.error = true;
            errorObject.message = "Please enter a valid date";
		} else if (!formData.origin.length) {
			errorObject.error = true;
            errorObject.message = "Please select a value for origin.";
        } else if (formData.origin == "other" && !formData.originOther.length) {
            errorObject.error = true;
            errorObject.message = "If origin is \"other\", Other Origin is a required field.";
        } else if (!new RegExp(dateRegex).test(formData.followUpDate)) {
            errorObject.error = true;
            errorObject.message = "Please enter a valid follow up date";
        }
        return errorObject;
    }

    return <Form
        initialState={initialState}
        isEdit={isEdit}
        isModal={isModal}
        objectName={InteractionObjectLayout.object_name}
        onClickExitHandler={onClickExitHandler}
        onClickSaveHandler={onClickSaveHandler}
        validate={validateInteractionForm}
    >
		{!isEdit ? <FormField name={"contactId"} label={"Contact ID"} is={"input"} /> : <React.Fragment></React.Fragment>}
        <FormField name={"date"} label={"Date"} is={"input"} type={"date"} />
        <FormField name={"origin"} label={"Origin"} is={"select"} options={InteractionObjectLayout.value_options.origin} otherable={true} />
        <FormField name={"comment"} label={"Comment"} is={"textarea"} />
        <FormField name={"followUpDate"} label={"Follow Up Date"} is={"input"} type={"date"} />
        <FormField name={"followUpComplete"} label={"Follow Up Complete"} is={"select"} options={InteractionObjectLayout.value_options.followUpComplete} otherable={false} />
    </Form>
}

export default InteractionForm;