import * as React from "react";
// Hooks
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
// Utils
import getTableRows from "utils/getTableRows";
import { AppNavigator } from "utils/AppNavigator";
// Constants
import { ObjectLayout, adminPath, objectLayoutData } from "../../constants"
// Components
import { LoadingPage } from "components/LoadingPage";
import { AddButtonLabel, Button, ButtonGroup, Table, TableBody, TableClickableRow, TableHead, TableItem, TableWrapper } from "components/Elements";

interface ObjectTableProps {

}

const ObjectTable: React.FC<ObjectTableProps> = ({

}) => {
    // Hooks
    let { objectRoute } = useParams();
    let navigate = useNavigate();
    let navigator = new AppNavigator(navigate)
    const objects = useSelector((state: RootState) => {
        if (state.hasOwnProperty(objectRoute)) {
            return state[objectRoute].list
        } else {
            // TODO: Throw Exception
            return null
        }
    })
    // Configure Data
    const dataLayout: ObjectLayout = objectLayoutData[objectRoute];
    // Event Handlers
    const onClickRow = (obj: object) => {
        if (obj.hasOwnProperty("id")) {
            navigator.view(dataLayout.object_route, obj["id"]);
            // navigate(`${adminPath}/${dataLayout.object_route}/view/${obj["id"]}`)
        }
    }
    const onClickAdd = (event) => {
        navigator.add(dataLayout.object_route);
        // navigate(`${adminPath}/${dataLayout.object_route}/add`)
    }

    if (objects == null)
        return <LoadingPage darkModeEnabled={false} />
    return <TableWrapper>
        <ButtonGroup>
            <Button onClick={onClickAdd}>
                <AddButtonLabel objectName={dataLayout.object_name} />
            </Button>
        </ButtonGroup>
        <Table>
            <TableHead headers={dataLayout.table_headers} />
            <TableBody>
                {getTableRows(
                    dataLayout,
                    onClickRow,
                    objects
                )}
            </TableBody>
        </Table>
    </TableWrapper>
}

export default ObjectTable;