import * as React from "react";
import IFormTextAreaProps from "props/IFormTextAreaProps";

interface Props extends IFormTextAreaProps {
    placeholder: string
}

const FormTextArea: React.FC<Props> = ({ name, onChange, value, rows=5, placeholder }) => {
    return <textarea className="w-full py-4 px-2 m-2" rows={rows} style={{ "resize": "none" }} placeholder={placeholder} required name={name} value={value} onChange={onChange}></textarea>
}

export default FormTextArea;