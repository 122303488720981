import * as React from "react";
import {
    SectionSubHeader,
    SectionContent
} from "components/Elements"

interface Props {
    blurb: string,
    index: number,
    name: string,
    profile_picture: any,
    title: string
}

const TeamMemberDescription : React.FC<Props> = ({ index, title, name, profile_picture, blurb }) => {
    const imageClass = `w-48 md:w-64 rounded-full grayscale-75 ${index % 2 ? 'float-left' : 'float-right'}`
    return <div className={index > 0 ? "mt-8" : ""}>
        <SectionSubHeader>
            <span className="font-bold">{title}:</span> {name}
            <img src={profile_picture} className={imageClass} alt="Michael" />
        </SectionSubHeader>
        <SectionContent>
            {blurb}
        </SectionContent>
    </div>
}

export default TeamMemberDescription;