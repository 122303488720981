import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const ContentWrapper : React.FC<Props>  = ({ children, className = "" }) => {
    return <div className={`w-5/6 lg:w-4/6 py-8 ${className}`}>
        {children}
    </div>
}

export default ContentWrapper;