import * as React from "react";
import { Section, SectionContentWrapper } from "components/Elements";
import { advanced_classes, basic_classes, classes_header, intermediate_classes, intro_classes } from "components/LandingPage/data";
import ClassContent from "./ClassContent";
import ClassDescription from "./ClassDescription";
import ClassesHeader from "./ClassesHeader";
import ClassGrid from "./ClassGrid";
import ClassTab from "./ClassTab";
import ClassTabSection from "./ClassTabSection";

const ClassesSection: React.FC = () => {
    // State
    const [tab, setTab] = React.useState(0)

    return <Section className="bg-primary-blue" id="classes">
        <SectionContentWrapper>
            <ClassesHeader {...classes_header} />
            <ClassGrid>
                <ClassTabSection>
                    <ClassTab index={0} tab={tab} setTab={setTab}>Intro</ClassTab>
                    <ClassTab index={1} tab={tab} setTab={setTab}>Basic</ClassTab>
                    <ClassTab index={2} tab={tab} setTab={setTab}>Intermediate</ClassTab>
                    <ClassTab index={3} tab={tab} setTab={setTab}>Advanced</ClassTab>
                </ClassTabSection>

                <ClassContent index={0} tab={tab}>
                    {intro_classes.map((classItem, ind) => <ClassDescription key={`intro-${ind}`} data={classItem} renderDivider={intro_classes.length != ind + 1} />)}
                </ClassContent>
                <ClassContent index={1} tab={tab}>
                    {basic_classes.map((classItem, ind) => <ClassDescription key={`basic-${ind}`} data={classItem} renderDivider={basic_classes.length != ind + 1} />)}
                </ClassContent>
                <ClassContent index={2} tab={tab}>
                    {intermediate_classes.map((classItem, ind) => <ClassDescription key={`intermediate-${ind}`} data={classItem} renderDivider={intermediate_classes.length != ind + 1} />)}
                </ClassContent>
                <ClassContent index={3} tab={tab}>
                    {advanced_classes.map((classItem, ind) => <ClassDescription key={`advanced-${ind}`} data={classItem} renderDivider={advanced_classes.length != ind + 1} />)}
                </ClassContent>
            </ClassGrid>
        </SectionContentWrapper>
    </Section>
}

export default ClassesSection;