import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const SubHeader: React.FC<Props> = ({ children, className = "" }) => {
    return <h3 className={`text-xl md:text-2xl lg:text-4xl text-slate-900 ${className}`}>{children}</h3>
}

export default SubHeader;