import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from "features/authentication/authenticationSlice";
import navigationReducer from "features/navigation/navigationSlice";
import contactsReducer from "features/contact/contactsSlice";
import interactionsReducer from "features/interaction/interactionsSlice";
import userReducer from "features/user/usersSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        navigation: navigationReducer,
        contacts: contactsReducer,
        interactions: interactionsReducer,
        users: userReducer
    },
})

// Infer the 'RootState' and 'AppDispatch' types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {reducerName: reducerNameState}
export type AppDispatch = typeof store.dispatch;

// Use throught app instead of plain 'useDispatch' and 'useSelector'
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector