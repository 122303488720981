import React from "react"
import { connect, useDispatch } from "react-redux"
// Constants

// Context
import { RootState } from "app/store"
import { Outlet, useLocation, useParams } from "react-router-dom"
import { useApiClient } from "containers/ApiContext"
// Features
import { Contact } from "features/contact/contactsSlice"
import { ContactState, mapContactToState, mapStateToContact } from "features/contact/mappers"
import { Interaction } from "features/interaction/interactionsSlice"
import { InteractionState, getInitialInteractionState, mapInteractionToState, mapStateToInteraction } from "features/interaction/mappers"
import { Parent, User } from "features/user/usersSlice"
import { ModalType, clearModal } from "features/navigation/navigationSlice"
// Components
import { LoadingPage } from "components/LoadingPage"
import { Dashboard } from "components/Dashboard"
import Modal from "../Modal/Modal"
import { ContactsButtonLabel, DashboardButtonLabel, InteractionsButtonLabel, NavBar, SendMailButtonLabel, SideBar, SideBarButton, UserButtonLabel } from "components/Elements"
import { spoofContacts, spoofInteractions, spoofUsers } from "app/spoofs"
import { ContactForm, InteractionForm, UserForm } from "../Form"
import { ParentDTO, ParentState, getInitialParentState, getInitialUserState, mapParentToState, mapStateToParentDTO } from "../../features/user/mappers"
import ParentForm from "../Form/ParentProfileForm"

interface AdminPanelStateProps {
	contacts: Array<Contact> | null,
	interactions: Array<Interaction> | null,
	users: Array<User> | null,
	modalType: ModalType,
	modalShow: boolean,
	modalObject: {
		[key: string]: any
	}
}

interface AdminPanelProps extends AdminPanelStateProps {

}

export const AdminPanel: React.FC<AdminPanelProps> = ({ contacts, interactions, users, modalObject, modalType, modalShow }) => {
	/* 
	 * Flag to determine whether render loadin screen or not
	 * - value: bool
	 * - defaultValue: true
	 */
	const [loading, setLoading] = React.useState(true)
	// Hooks
	// - Redux
	const dispatch = useDispatch()
	// - ApiContext
	const client = useApiClient()
	// - React-Router-Dom
	let { guid } = useParams();

	/*
	 * Load Data
	 */
	React.useEffect(() => {
		if (contacts === null) {
			client.readContacts()
		}

		if (interactions === null) {
			client.readInteractions()
		}

		if (users === null) {
			client.readUsers()
		}
	}, [])

	/*
	 * Resolve Loading
	 * - Sets loading flag to false when all data has been loaded
	 */
	React.useEffect(() => {
		if (contacts !== null && interactions !== null && users !== null) {
			setLoading(false)
		}
	}, [contacts, interactions, users])

	/*
	 * Modal Save Event Handlers
	 */
	const saveAddInteractionModal = (formData: InteractionState) => {
		var postData: Interaction = mapStateToInteraction(formData)
		client.createInteraction(postData, true)
	}
	const saveEditInteractionModal = (formData: InteractionState) => {
		var postData: Interaction = mapStateToInteraction(formData)
		client.updateInteraction(modalObject.id, postData, true)
	}
	const saveEditContactModal = (formData: ContactState) => {
		var postData: Contact = mapStateToContact(formData)
		client.updateContact(modalObject.id, postData, true)
	}
	const saveAddParentProfileModal = (formData: ParentState) => {
		var postData: ParentDTO = mapStateToParentDTO(formData)
		client.addParentProfile(guid, postData)
	}
	const saveEditParentProfileModal = (formData: ParentState) => {
		var postData: ParentDTO = mapStateToParentDTO(formData)
		client.updateParentProfile(guid, postData)
	}

	/*
	 * Modal Render Helpers
	 */
	const getModalContents = () => {
		if (modalObject === null && modalType !== null) {
			if (modalType.includes("edit")) {
				return <LoadingPage fullScreen={false} />
			}
		}
		switch (modalType) {
			case "add-interaction":
				return <InteractionForm
					initialState={{
						...getInitialInteractionState(),
						contactId: guid
					}} isEdit={false} isModal={true}
					onClickExitHandler={() => dispatch(clearModal())}
					onClickSaveHandler={saveAddInteractionModal}
				/>

			case "edit-interaction":
				return <InteractionForm
					initialState={mapInteractionToState(modalObject as Interaction)}
					isEdit={true} isModal={true}
					onClickExitHandler={() => dispatch(clearModal())}
					onClickSaveHandler={saveEditInteractionModal}
				/>

			case "edit-contact":
				return <ContactForm
					initialState={mapContactToState(modalObject as Contact)}
					isEdit={true} isModal={true}
					onClickExitHandler={() => dispatch(clearModal())}
					onClickSaveHandler={saveEditContactModal}
				/>

			case "add-parent-profile":
				return <ParentForm
					initialState={getInitialParentState()}
					isEdit={false} isModal={true}
					onClickExitHandler={() => dispatch(clearModal())}
					onClickSaveHandler={saveAddParentProfileModal}
				/>

			case "edit-parent-profile":
				return <ParentForm
					initialState={mapParentToState(modalObject as Parent)}
					isEdit={true} isModal={true}
					onClickExitHandler={() => dispatch(clearModal())}
					onClickSaveHandler={saveEditParentProfileModal}
				/>
			default:
				return null
		}
	}

	// If loading process is occurring, return loading page
	if (loading)
		return <LoadingPage />
	// Otherwise, render application
	return <div>
		<SideBar>
			<SideBarButton href={"/admin"}>
				<DashboardButtonLabel />
			</SideBarButton>
			<SideBarButton href={"/admin/contacts"}>
				<ContactsButtonLabel />
			</SideBarButton>
			<SideBarButton href={"/admin/interactions"}>
				<InteractionsButtonLabel />
			</SideBarButton>
			<hr />
			<SideBarButton href={"/admin/users"}>
				<UserButtonLabel />
			</SideBarButton>
			<SideBarButton href={"/admin/send-mass-mail"}>
				<SendMailButtonLabel pageName={"Send Mass Email"} />
			</SideBarButton>
		</SideBar>
		<div className="p-4 lg:ml-64">
			<div className="pt-8 mt-8">
				<Outlet />
			</div>
		</div>
		<Modal show={modalShow}>
			{getModalContents()}
		</Modal>
	</div>
}

const mapAdminStateToProps = (state: RootState): AdminPanelStateProps => ({
	contacts: state.contacts.list,
	interactions: state.interactions.list,
	users: state.users.list,
	modalType: state.navigation.modalType,
	modalShow: state.navigation.modalShow,
	modalObject: state.navigation.modalObject
});
export const ConnectedAdminPanel = connect(mapAdminStateToProps, {})(AdminPanel);

const mapSpoofToProps = (state: RootState): AdminPanelStateProps => ({
	contacts: spoofContacts,
	interactions: spoofInteractions,
	users: spoofUsers,
	modalType: state.navigation.modalType,
	modalShow: state.navigation.modalShow,
	modalObject: state.navigation.modalObject
});
export const SpoofAdminPanel = connect(mapSpoofToProps, {})(AdminPanel);

export default AdminPanel;