import * as React from "react";
import { ContactObjectLayout, InteractionObjectLayout, UserObjectLayout } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { useApiContext } from "../ApiContext";
import { useSelector } from "react-redux";
// Features
import { RootState } from "app/store";
import { Contact } from "features/contact/contactsSlice";
import { ContactState, getInitialContactState, mapContactToState, mapStateToContact } from "features/contact/mappers";
import { Interaction } from "features/interaction/interactionsSlice";
import { InteractionState, getInitialInteractionState, mapInteractionToState, mapStateToInteraction } from "features/interaction/mappers";
import { User } from "features/user/usersSlice";
import { UserDTO, UserState, getInitialUserState, mapStateToUserDTO, mapUserToState } from "features/user/mappers";
// Utils
import { AppNavigator } from "utils/AppNavigator";
// Components
import { ContactForm, InteractionForm, UserForm } from "../Form";
import { LoadingPage } from "components/LoadingPage";


interface ObjectFormProps {
    mode: "add" | "edit"
}

const ObjectForm: React.FC<ObjectFormProps> = ({
    mode
}) => {
    const { client } = useApiContext()
    const { objectRoute, guid } = useParams();
    const selected = useSelector((state: RootState) => state[objectRoute].list.find(obj => obj.id == guid))
    // Navigation
    const navigate = useNavigate()
    const navigator = new AppNavigator(navigate);

    if (selected === null) {
        return <LoadingPage darkModeEnabled={false} />
    } else if (objectRoute == ContactObjectLayout.object_route) {
        // Initial State
        let initialState = mode === "add" ? getInitialContactState() : mapContactToState(selected as Contact)
        // On Click Exit
        let onClickExitHandler = mode === "add" ? () => navigator.contactList() : () => navigator.contactView(guid);
        // On Click Save
        let onClickSaveHandler = mode === "add" ? (formData: ContactState) => {
            var postData: Contact = mapStateToContact(formData)
            client.createContact(postData)
        } : (formData: ContactState): void => {
            var postData: Contact = mapStateToContact(formData)
            client.updateContact(guid, postData)
        }
        return <ContactForm
            initialState={initialState}
            isEdit={mode === "edit"}
            isModal={false}
            onClickExitHandler={onClickExitHandler}
            onClickSaveHandler={onClickSaveHandler}
        />
    } else if (objectRoute == InteractionObjectLayout.object_route) {
        // Initial State
        let initialState = mode === "add" ? getInitialInteractionState() : mapInteractionToState(selected as Interaction)
        // On Click Exit
        let onClickExitHandler = mode === "add" ? () => navigator.interactionList() : () => navigator.interactionView(guid);
        // On Click Save
        let onClickSaveHandler = mode === "add" ? (formData: InteractionState) => {
            var postData: Interaction = mapStateToInteraction(formData)
            client.createInteraction(postData)
        } : (formData: InteractionState): void => {
            var postData: Interaction = mapStateToInteraction(formData)
            client.updateInteraction(guid, postData)
        }
        return <InteractionForm
            initialState={initialState}
            isEdit={mode === "edit"}
            isModal={false}
            onClickExitHandler={onClickExitHandler}
            onClickSaveHandler={onClickSaveHandler}
        />
    } else if (objectRoute == UserObjectLayout.object_route) {
        // Initial State
        let initialState = mode === "add" ? getInitialUserState() : mapUserToState(selected as User)
        // On Click Exit
        let onClickExitHandler = mode === "add" ? () => navigator.userList() : () => navigator.userView(guid);
        // On Click Save
        let onClickSaveHandler = mode === "add" ? (formData: UserState) => {
            var postData: UserDTO = mapStateToUserDTO(formData)
            console.warn(postData)
            client.createUser(postData)
        } : (formData: UserState): void => {
            var postData: UserDTO = mapStateToUserDTO(formData)
            console.warn(guid, postData)
            client.updateUser(guid, postData)
        }
        return <UserForm
            initialState={initialState}
            isEdit={mode === "edit"}
            isModal={false}
            onClickExitHandler={onClickExitHandler}
            onClickSaveHandler={onClickSaveHandler}
        />
    }
    return <></>
}

export default ObjectForm;