import * as React from "react";
import { IconMail} from "components/Elements";
import ILabelPageNameProps from "./props/ILabelPageNameProps";

interface Props extends ILabelPageNameProps { }

const SendMailButtonLabel: React.FC<Props> = ({ pageName }) => {
    return <><IconMail className="mr-2" /> {pageName}</>
}

export default SendMailButtonLabel;