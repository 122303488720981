import * as React from "react"
import { ExitButton } from 'components/Elements'
import IChildrenProps from "../../props/IChildrenProps"

interface Props extends IChildrenProps {
    show: boolean
}

const Modal : React.FC<Props> = ({ children, show }) => {
    return <ModalBase show={show}>
        {children}
    </ModalBase>
}

export function ModalBase({ children, show }) {
    return <div className={`fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full justify-center items-center flex ${show ? 'block' : 'hidden'} bg-gray-900 bg-opacity-75`}>
        <div className="relative w-full max-w-2xl max-h-full opacity-100">
            <div className="relative bg-white shadow dark:bg-gray-700">
                {children}
            </div>
        </div>
    </div>
}

export function ModalHeader({ children }) {
    return <div className="flex items-start justify-between p-4 borber-b rounded-t dark:border-gray-600">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {children}
        </h3>
    </div>
}

export function ModalBody({ children }) {
    return <div className="p-6 space-y-6">
        {children}
    </div>
}

export function ModalBodyWithExit({ children, exitOnClick }) {
    return <ModalBody>
        <ExitButton onClick={exitOnClick} />
        {children}
    </ModalBody>
}

export function ModalFooter({ children }) {
    return <div className="flex items-cetner p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        {children}
    </div>
}

export function ModalCloseButton() {

}

export default Modal;