import * as React from "react";
import { IconEdit } from "components/Elements";
import ILabelObjectNameProps from "./props/ILabelObjectNameProps";

interface Props extends ILabelObjectNameProps { }

const EditButtonLabel: React.FC<Props> = ({ objectName }) => {
    return <><IconEdit className="mr-2" /> Edit {objectName}</>
}

export default EditButtonLabel;