import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthenticationState {
    token: string | null
}

const initialState: AuthenticationState = {
    token: null
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        }
    }
})

export const { setToken } = authenticationSlice.actions;

export default authenticationSlice.reducer;