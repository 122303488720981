import * as React from "react"
import createOnChange from "utils/createOnChange";
import IObjectFormProps from "props/IObjectFormProps";
import {
    BackToTableButton, Button, ButtonGroup,
    CancelButtonLabel,
    CreateButtonLabel,
    FormAlert, FormHeader, FormWrapper, SaveButtonLabel
} from "components/Elements"
import { FieldProps } from "components/Elements/Form/Field";

interface FormDivProps {
    children: React.ReactNode,
    className?: ""
}

const FormDiv: React.FC<FormDivProps> = ({ children, className = "" }) => {
    return <form className={`uppercase text-base md:text-md lg:text-lg py-4 w-full dark:bg-gray-600 ${className}`}>{children}</form>
}

export type FormErrorObject = {
    error: boolean,
    message: string | null
}

interface Props extends IObjectFormProps { }

const Form: React.FC<Props> = ({
    children,
    initialState,
    isEdit,
    isModal,
    objectName,
    onClickExitHandler,
    onClickSaveHandler,
    validate
}) => {
    const [formData, setFormData] = React.useState(initialState);
    // Alerts
    const [errored, setErrored] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState("")
    const setError = (bool: boolean, msg: string) => {
        setErrorMsg(msg);
        setErrored(bool);
    }
    const resetError = () => {
        setError(false, "");
    }

    const performValidation = () => {
        resetError()
        let { error, message } = validate(formData)
        if (error) {
            setError(true, message)
            return false
        }
        return true
    }

    const onClickValidateAndSaveHandler = (event: React.MouseEvent) => {
        if (performValidation())
            onClickSaveHandler(formData)
    }

    const onFormChange = createOnChange(formData, setFormData);

    return <div>
        {!isModal && <ButtonGroup>
            <BackToTableButton onClick={onClickExitHandler} />
        </ButtonGroup>}
        <FormWrapper>
            <FormDiv>
                <div>
                    <FormHeader>
                        {isEdit ? `Edit ${objectName}` : `Add ${objectName}`}
                    </FormHeader>
                    {React.Children.map(children, (child) => {
						if (child !== null) {
							let props: FieldProps = {
								onChange: onFormChange,
								value: formData[child.props.name],
								otherValue: child.props.otherable ? formData[`${child.props.name}Other`] : undefined,
								...child.props
							}

							return React.cloneElement(child, props);
						}
                    })}
                    <FormAlert submitted={false} errored={errored} errorMsg={errorMsg} />
                    <ButtonGroup>
                        <Button colour={"green"} onClick={onClickValidateAndSaveHandler}>{isEdit ?
                            <SaveButtonLabel /> :
                            <CreateButtonLabel />
                        }</Button>
                        <Button colour={"red"} onClick={onClickExitHandler}>
                            <CancelButtonLabel />
                        </Button>
                    </ButtonGroup>
                </div>
            </FormDiv>
        </FormWrapper>
    </div>
}

export default Form;