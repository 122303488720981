// icon:cancel | Entypo http://entypo.com/ | Daniel Bruce
import * as React from "react";

function IconCancel(props) {
  return (
    <svg
      viewBox="0 0 470 1000"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M452 656c12 12 18 26.333 18 43s-6 31-18 43c-12 10.667-26.333 16-43 16s-31-5.333-43-16L234 590 102 742c-12 10.667-26.333 16-43 16s-31-5.333-43-16C5.333 730 0 715.667 0 699s5.333-31 16-43l138-156L16 342C5.333 330 0 315.667 0 299s5.333-31 16-43c12-10.667 26.333-16 43-16s31 5.333 43 16l132 152 132-152c12-10.667 26.333-16 43-16s31 5.333 43 16c12 12 18 26.333 18 43s-6 31-18 43L314 500l138 156" />
    </svg>
  );
}

export default IconCancel;
