import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const TableBody: React.FC<Props> = ({ children, className = "" }) => {
    return <tbody className={className}>
        {children}
    </tbody>
}

export default TableBody;