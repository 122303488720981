import { Interaction } from "features/interaction/interactionsSlice";
import { Contact } from "features/contact/contactsSlice";
import { Address } from "features/commonEntities";
import { User } from "../features/user/usersSlice";

export const booleanToString = (bool: boolean): string => bool ? "True" : "False"

export const stringArrayToString = (arr: Array<string>): string => {
	var val = "";
	arr.map(obj => {
		val += `${obj},`
	})
	val = val.slice(0, -1);
	return val
}

export const addressToString = (obj: Address): string => {
	return `${obj.line1} ${obj.line2} ${obj.line3} ${obj.city} ${obj.province} ${obj.postalCode} ${obj.country}`;
}

export const contactToString = (obj: Contact): string => {
	return `${obj.fname} ${obj.lname} - ${obj.status}`
}

export const interactionToString = (obj: Interaction): string => {
	return `${obj.followUpDate} [${obj.followUpComplete ? "Complete" : "Incomplete"}]`;
}

export const userToString = (obj: User): string => {
	return `${obj.userName} - ${obj.email}`;
}