import * as React from "react";
import { UserObjectLayout, emailRegex, phoneNumberRegex } from "../../constants";
// Features
import { UserState } from "features/user/mappers";
// Components
// Components
import {
    Form, FormErrorObject
} from "containers/Form"
import { FormField } from "components/Elements";

interface Props {
    initialState: UserState
    isEdit: boolean,
    isModal: boolean,
    onClickExitHandler: React.MouseEventHandler,
    onClickSaveHandler: (formData: UserState) => void
}

const UserForm: React.FC<Props> = ({ initialState, isEdit, isModal, onClickExitHandler, onClickSaveHandler }) => {

    const validateUserForm = (formData: { [key: string]: any }): FormErrorObject => {
        let errorObject: FormErrorObject = {
            error: false,
            message: null
        }
        if (!formData.fname.length) {
            errorObject.error = true
            errorObject.message = "First name is a required field."
            return errorObject
        } else if (!formData.lname.length) {
            errorObject.error = true
            errorObject.message = "Last name is a required field."
            return errorObject
        } else if (!formData.email.match(emailRegex)) {
            errorObject.error = true
            errorObject.message = "Please enter a valid email address."
            return errorObject
        } else if (!formData.phoneNumber.match(phoneNumberRegex)) {
            errorObject.error = true
            errorObject.message = "Please enter a valid phone number."
            return errorObject
        }
        return errorObject
    }

    return <Form
        initialState={initialState}
        isEdit={isEdit}
        isModal={isModal}
        objectName={UserObjectLayout.object_name}
        onClickExitHandler={onClickExitHandler}
        onClickSaveHandler={onClickSaveHandler}
        validate={validateUserForm}
    >
        <FormField name={"fname"} label={"First Name"} is={"input"} />
        <FormField name={"lname"} label={"Last Name"} is={"input"} />
        <FormField name={"userName"} label={"UserName"} is={"input"} />
        <FormField name={"email"} label={"Email"} is={"input"} />
		{isEdit && <FormField name={"emailConfirmed"} label={"Is Email Confirmed"} is={"select"} />}
        <FormField name={"phoneNumber"} label={"Phone Number"} is={"input"} />
        {isEdit && <FormField name={"phoneNumberConfirmed"} label={"Is Phone Number Confirmed"} is={"select"} />}
        {isEdit && <FormField name={"twoFactorEnabled"} label={"Is Two Factor Enabled"} is={"select"} />}
        <FormField name={"line1"} label={"Address Line 1"} is={"input"} />
        <FormField name={"line2"} label={"Address Line 2"} is={"input"} />
        <FormField name={"line3"} label={"Address Line 3"} is={"input"} />
        <FormField name={"city"} label={"City"} is={"input"} />
        <FormField name={"province"} label={"Province"} is={"input"} />
        <FormField name={"postalCode"} label={"Postal Code"} is={"input"} />
        <FormField name={"country"} label={"Country"} is={"input"} />
    </Form>
}

export default UserForm;