import * as React from "react";
import IExtensibleClassProps from "props/IExtensibleClassProps";
import IChildrenProps from "props/IChildrenProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const FieldWrapper: React.FC<Props> = ({ children, className = "" }) => <div
    className={`text-left {className}`}
>
    {children}
</div>

export default FieldWrapper;