// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MainContent */
:root{
    --primary-blue: #005b96;
    --primary-orange: #f87008;
    --title-color:  #161618;
    --text-color:  #353535;
}

/* ContactForm */
.t-display-none {
	display: none;
}
.t-alert {
	border-radius: 5px;
	color: white;
	margin-bottom: 24px;
	text-align: center;
}
.t-success-alert {
	background: rgba(0,128,0, 0.8);
}
.t-error-alert {
	background: rgba(255, 0, 0, 0.8);
}

/* New CSS */
.landing-page * {
    font-family: "Arvo", sans-serif;
    overflow-anchor: none;
}

.bg-primary-blue {
  background-color: var(--primary-blue);
}

.bg-navbar-blue {
  background-color: #03396c;
}

.grayscale-75 {
  filter: grayscale(0.75);
  color: #212529;
}

.custom-tab {
  background: #0072BC;
  color: white;
}
.custom-tab:hover {
  background: #0089E2; /*1485be*/
  color: white;
}
.custom-tab.active {
  background: var(--primary-orange);
  color: white;
}
.custom-tab.active:hover {
  background: var(--primary-orange);
  color: white;
}

.text-orange-highlight {
  color:var(--primary-orange);
}`, "",{"version":3,"sources":["webpack://./src/styles/LandingPage.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,uBAAuB;IACvB,yBAAyB;IACzB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA,gBAAgB;AAChB;CACC,aAAa;AACd;AACA;CACC,kBAAkB;CAClB,YAAY;CACZ,mBAAmB;CACnB,kBAAkB;AACnB;AACA;CACC,8BAA8B;AAC/B;AACA;CACC,gCAAgC;AACjC;;AAEA,YAAY;AACZ;IACI,+BAA+B;IAC/B,qBAAqB;AACzB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,mBAAmB,EAAE,SAAS;EAC9B,YAAY;AACd;AACA;EACE,iCAAiC;EACjC,YAAY;AACd;AACA;EACE,iCAAiC;EACjC,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["/* MainContent */\r\n:root{\r\n    --primary-blue: #005b96;\r\n    --primary-orange: #f87008;\r\n    --title-color:  #161618;\r\n    --text-color:  #353535;\r\n}\r\n\r\n/* ContactForm */\r\n.t-display-none {\r\n\tdisplay: none;\r\n}\r\n.t-alert {\r\n\tborder-radius: 5px;\r\n\tcolor: white;\r\n\tmargin-bottom: 24px;\r\n\ttext-align: center;\r\n}\r\n.t-success-alert {\r\n\tbackground: rgba(0,128,0, 0.8);\r\n}\r\n.t-error-alert {\r\n\tbackground: rgba(255, 0, 0, 0.8);\r\n}\r\n\r\n/* New CSS */\r\n.landing-page * {\r\n    font-family: \"Arvo\", sans-serif;\r\n    overflow-anchor: none;\r\n}\r\n\r\n.bg-primary-blue {\r\n  background-color: var(--primary-blue);\r\n}\r\n\r\n.bg-navbar-blue {\r\n  background-color: #03396c;\r\n}\r\n\r\n.grayscale-75 {\r\n  filter: grayscale(0.75);\r\n  color: #212529;\r\n}\r\n\r\n.custom-tab {\r\n  background: #0072BC;\r\n  color: white;\r\n}\r\n.custom-tab:hover {\r\n  background: #0089E2; /*1485be*/\r\n  color: white;\r\n}\r\n.custom-tab.active {\r\n  background: var(--primary-orange);\r\n  color: white;\r\n}\r\n.custom-tab.active:hover {\r\n  background: var(--primary-orange);\r\n  color: white;\r\n}\r\n\r\n.text-orange-highlight {\r\n  color:var(--primary-orange);\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
