import * as React from "react";

const NavToggleSVG : React.FC = () => {
    return <div className="space-y-2 my-2">
        <div className="w-8 h-0.5 bg-white"></div>
        <div className="w-8 h-0.5 bg-white"></div>
        <div className="w-8 h-0.5 bg-white"></div>
    </div>
}

export default NavToggleSVG;