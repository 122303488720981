import { createContext, useContext } from "react";
import { store } from "app/store";
import { ActionCreatorWithPayload, AnyAction } from "@reduxjs/toolkit";
import { Contact, loadContacts } from "features/contact/contactsSlice";
import { Interaction, loadInteractions } from "features/interaction/interactionsSlice";
import { setToken } from "features/authentication/authenticationSlice";
import { User, loadUsers } from "features/user/usersSlice";
import { ParentDTO, UserDTO } from "features/user/mappers";
import { AxiosInstance, CreateAxiosDefaults, create as createAxiosInstance } from "axios";
import { ContactObjectLayout, InteractionObjectLayout, ParentProfileObjectLayout, UserObjectLayout, authEndpoint, checkTokenEndpoint, tokenEndpoint } from "../../constants";
import { MassMailObject } from "containers/SendMassMailPage/SendMassMailPage";

// TODO: Refresh token in API context if returns unauthorized

export type ApiConfig = {
    [key: string]: any
}

export type ResultsCallback = (res: any) => void
export type ErrorCallback = (err: any) => void
export type FinalCallback = () => void

export type PostData = { [key: string]: any }
export type AuthData = { username: string, password: string }

export interface IApiClient {
    client: AxiosInstance

    _setToken: (token: string) => void
    _getToken: () => string

    authenticate: (data: AuthData, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback?: FinalCallback) => void,
    checkToken: (resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback?: FinalCallback) => void,
    get: (route: string, config: ApiConfig, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback?: FinalCallback) => void,
    post: (route: string, data: PostData, config: ApiConfig, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback?: FinalCallback) => void,
    put: (route: string, data: PostData, config: ApiConfig, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback?: FinalCallback) => void,
    del: (route: string, config: ApiConfig, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback?: FinalCallback) => void
}

class ApiClient implements IApiClient {
    client: AxiosInstance;

    constructor(axiosConfig: CreateAxiosDefaults) {
        this.client = createAxiosInstance(axiosConfig);
    }

    // Token Setter/Getter
    _setToken = (token) => store.dispatch(setToken(token))
    _getToken = () => store.getState().authentication.token

    // Create Config from defaults and optional configuration options
    private getConfig = (configOptions: ApiConfig = {}): ApiConfig => ({
		headers: {
			accept: "application/json",
			Authorization: `Bearer ${this._getToken()}`,
		},
		...configOptions
    })

    // Default Error Callback
    public errCallback: ErrorCallback = (err) => alert(err.message)

    // Retrieve token from API using Username and Password authentication
    public authenticate = (data: AuthData, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback: FinalCallback = () => { }) => {
        this.client.post(`${authEndpoint}${tokenEndpoint}`, data)
            .then(resCallback)
            .catch(errCallback)
            .finally(finalCallback)
    }

    // Check the currently configured token to ensure it is still valid for authorization
    public checkToken = (resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback: FinalCallback = () => { }) => {
        this.client.get(`${authEndpoint}${checkTokenEndpoint}/`, this.getConfig())
            .then(resCallback)
            .catch(errCallback)
            .finally(finalCallback)
    }

    // Default Endpoints
    public get = (route: string, config: ApiConfig, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback: FinalCallback = () => { }) => {
        this.client.get(route, this.getConfig(config))
            .then(resCallback)
            .catch(errCallback)
            .finally(finalCallback)
    }
    public post = (route: string, data: PostData, config: ApiConfig, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback: FinalCallback = () => { }) => {
        this.client.post(route, data, this.getConfig(config))
            .then(resCallback)
            .catch(errCallback)
            .finally(finalCallback)
    }
    public put = (route: string, data: PostData, config: ApiConfig, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback: FinalCallback = () => { }) => {
        this.client.put(route, data, this.getConfig(config))
            .then(resCallback)
            .catch(errCallback)
            .finally(finalCallback)
    }
    public del = (route: string, config: ApiConfig, resCallback: ResultsCallback, errCallback: ErrorCallback, finalCallback: FinalCallback = () => { }) => {
        this.client.delete(route, this.getConfig(config))
            .then(resCallback)
            .catch(errCallback)
            .finally(finalCallback)
    }

    
    /*
     * API Specific Operations - Particularly CRUD Operations
     */

    // Callback Generators
    private getUpdatResCallback = (objectRoute: string, guid: string, reloadInPlace: boolean = false): ResultsCallback => {
        return (res) => {
            if (reloadInPlace)
                window.location.reload()
            else
                window.location.href = `/admin/${objectRoute}/view/${guid}`
        }
    }

    // CRUD Endpoints
    private createObject = (objectRoute: string, data: PostData, reloadInPlace: boolean = false) => {
        const resCallback: ResultsCallback = (res) => {
			if (reloadInPlace)
				window.location.reload()
			else
				window.location.href = `/admin/${objectRoute}/view/${res.data.id}`
		}
        this.post(`/${objectRoute}`, data, {}, resCallback, this.errCallback)
    }

    private createChildObject = (objectRoute: string, childRoute: string, guid: string, data: PostData) => {
        this.createObject(`${objectRoute}/${guid}/${childRoute}`, data, true)
    }

    private readObjects = <T,>(objectRoute: string, action: ActionCreatorWithPayload<Array<T>>) => {
        const resCallback: ResultsCallback = (res) => store.dispatch(action(res.data))
        const customErrCallback: ErrorCallback = (err) => alert(`Error while attempting to retrieve ${objectRoute}: ${err.message}`)
        this.get(`/${objectRoute}`, {}, resCallback, customErrCallback)
    }

    private updateObject = (objectRoute: string, guid: string, data: PostData, reloadInPlace: boolean = false) => {
        const resCallback: ResultsCallback = this.getUpdatResCallback(objectRoute, guid, reloadInPlace)
        this.put(`/${objectRoute}/${guid}`, data, {}, resCallback, this.errCallback)
    }

    private updateChildObject = (objectRoute: string, childRoute: string, guid: string, data: PostData) => {
        const resCallback: ResultsCallback = this.getUpdatResCallback(objectRoute, guid, true)
        this.put(`/${objectRoute}/${guid}/${childRoute}`, data, {}, resCallback, this.errCallback)
    }

    private deleteObject = (objectRoute: string, guid: string) => {
        const resCallback: ResultsCallback = (res) => window.location.href = `admin/${objectRoute}`
        this.del(`${objectRoute}/${guid}`, {}, resCallback, this.errCallback)
    }

    private deleteChildObject = (objectRoute: string, guid: string, parent: string, pGuid: string) => {
        const resCallback: ResultsCallback = (res) => window.location.href = `admin/${parent}/view/${pGuid}`
        this.del(`${objectRoute}/${guid}`, {}, resCallback, this.errCallback)
    }

    /*
     * Contacts
     */
    public createContact = (data: Contact, reloadInPlace: boolean = false) => this.createObject(ContactObjectLayout.object_route, data, reloadInPlace)
    public readContacts = () => this.readObjects<Contact>(ContactObjectLayout.object_route, loadContacts)
    public updateContact = (guid: string, data: Contact, reloadInPlace: boolean = false) => this.updateObject(ContactObjectLayout.object_route, guid, data, reloadInPlace)
    public deleteContact = (guid: string) => this.deleteObject(ContactObjectLayout.object_route, guid)

    /*
     * Interactions
     */
    public createInteraction = (data: Interaction, reloadInPlace: boolean = false) => this.createObject(InteractionObjectLayout.object_route, data, reloadInPlace)
    public readInteractions = () => this.readObjects<Interaction>(InteractionObjectLayout.object_route, loadInteractions)
    public updateInteraction = (guid: string, data: Interaction, reloadInPlace: boolean = false) => this.updateObject(InteractionObjectLayout.object_route, guid, data, reloadInPlace)
    public deleteInteraction = (guid: string) => this.deleteObject(InteractionObjectLayout.object_route, guid)

    /*
     * Users
     */
    public createUser = (data: UserDTO, reloadInPlace: boolean = false) => this.createObject(UserObjectLayout.object_route, data, reloadInPlace)
    public readUsers = () => this.readObjects<User>(UserObjectLayout.object_route, loadUsers)
    public updateUser = (guid: string, data: UserDTO, reloadInPlace: boolean = false) => this.updateObject(UserObjectLayout.object_route, guid, data, reloadInPlace)
    public deleteUser = (guid: string) => this.deleteObject(UserObjectLayout.object_route, guid)
    public addParentProfile = (guid: string, data: ParentDTO) => this.createChildObject(
        UserObjectLayout.object_route,
        ParentProfileObjectLayout.object_route,
        guid,
        data
    )
    public updateParentProfile = (guid: string, data: ParentDTO) => this.updateChildObject(
        UserObjectLayout.object_route,
        ParentProfileObjectLayout.object_route,
        guid,
        data
    )

    /*
     * Utility
     */
    public sendMassMail = (data: MassMailObject, resCallback: ResultsCallback) => this.post(`/${UserObjectLayout.object_route}/send-mass-mail`, data, {}, resCallback, this.errCallback)
}

export const createApiClient = (config: CreateAxiosDefaults): ApiClient => new ApiClient(config)

export type ApiContext = {
    client: ApiClient | null
}

export const ApiContext = createContext<ApiContext>({
    client: null
})

export const useApiContext = () => useContext(ApiContext);

export const useApiClient = () => {
    let { client } = useContext(ApiContext)
    return client
}