import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Contact } from '../contact/contactsSlice'

export type Interaction = {
    id?: string,
    contactId?: string,
    contact?: Contact | null,
    date: string,
    origin: string,
    comment: string,
    followUpDate: string,
    followUpComplete: boolean
}

interface InteractionsState {
    list: Array<Interaction> | null
}

const initialState: InteractionsState = {
    list: null
}

export const interactionsSlice = createSlice({
    name: "interactions",
    initialState,
    reducers: {
        loadInteractions: (state, action: PayloadAction<Array<Interaction>>) => {
            state.list = action.payload;
        }
    }
})

// Actions
export const { loadInteractions } = interactionsSlice.actions

export default interactionsSlice.reducer;