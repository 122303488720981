import { booleanToString, addressToString, contactToString, interactionToString, userToString, stringArrayToString } from "./toString";
import { instanceOfAddress, instanceOfContact, instanceOfEmptyArray, instanceOfInteraction, instanceOfInteractionsArray, instanceOfStringArray, instanceOfUser } from "./instanceOf"

export const toRenderable = (object: any): string => {
    if (object === null) {
        return "None"
    } else if (typeof object == "string") {
        // Is String
        return object;
    } else if (typeof object == "boolean") {
        // Is Boolean
        return booleanToString(object);
    } else if (typeof object == "object") {
        // Is Object
        if (instanceOfAddress(object)) return addressToString(object);
        else if (instanceOfContact(object)) return contactToString(object);
        else if (instanceOfInteraction(object)) return interactionToString(object);
        else if (instanceOfInteractionsArray(object)) return interactionToString(object[0])
        else if (instanceOfUser(object)) return userToString(object)
        else if (instanceOfEmptyArray(object)) return "-";
        else if (instanceOfStringArray(object)) return stringArrayToString(object)
        else return "";
    } else {
        // Is Non-Configured Type
        return "";
    }
}

export const keyValuePairToRenderable = (key: string, value: any, key_filters: Array<string> = []): string | null => {
    if (key_filters.includes(key))
        return null;
    else
        return toRenderable(value);
}

export const valueToRenderable = (value: any): string | null => {
    return toRenderable(value);
}