import React from "react";
import { TableClickableRow } from "components/Elements";
import getTableItems from "./getTableItems";
import { ObjectLayout } from "../constants";

const getTableRows = (
    data: ObjectLayout,
    onClickRow: (obj: object) => void,
    objects: Array<object>
) => {
    return objects.map((obj, ind) => <TableClickableRow
        onClick={() => onClickRow(obj)}
        key={`object-table-row-${data.object_name}-${ind}`}
    >
        {getTableItems(data, obj, ind)}
    </TableClickableRow>)
}

export default getTableRows;