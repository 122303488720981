import * as React from "react";
import { IconArrowLeft } from "components/Elements";
import ILabelPageNameProps from "./props/ILabelPageNameProps";

interface Props extends ILabelPageNameProps { }

const BackButtonLabel : React.FC<Props> = ({ pageName }) => {
    return <><IconArrowLeft className="mr-2" /> Back to {pageName}</>
}

export default BackButtonLabel;