import * as React from "react";
import IExtensibleClassProps from "props/IExtensibleClassProps";
import IFormInputProps from "props/IFormInputProps";

interface Props extends IExtensibleClassProps, IFormInputProps { }

const Input: React.FC<Props> = ({ className = "", disabled = false, name, onChange, placeholder = "", type = "text", value }) => <input
    className={`mb-4 p-1 border-2 w-full ${className}`}
    name={name}
    type={type}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    disabled={disabled}
/>

export default Input;