import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import { SectionContent } from "components/Elements";

interface Props extends IChildrenProps { }

const ClassBlurb: React.FC<Props> = ({ children }) => {
    return <div className="mt-8">
        <SectionContent>{children}</SectionContent>
    </div>
}

export default ClassBlurb;