import * as React from "react";
import IChildrenProps from "props/IChildrenProps";

interface Props extends IChildrenProps { }

const ClassTabSection : React.FC<Props> = ({ children }) => {
    return <div className="columns-1 md:columns-4 md:flex gap-0">
        {children}
    </div>
}

export default ClassTabSection;