import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";
import IIdProps from "props/IIdProps";

interface SectionProps extends IChildrenProps, IExtensibleClassProps, IIdProps { }


const Section: React.FC<SectionProps> = ({ children, className = "", id = "" }) => {
    return <section className={`flex items-center justify-center py-8 ${className}`} id={id}>
        {children}
    </section>
}

export default Section;