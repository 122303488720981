import * as React from "react";
import { navLinkClasses } from "./constants";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps {
    scrollElementId: string,
    toggle: boolean
}

const NavBtn : React.FC<Props> = ({ className = "", children, scrollElementId, toggle }) => {
    return <a className={`${navLinkClasses} ${className} ${toggle ? "" : "hidden md:block"}`}
        onClick={() => { if (document.getElementById(scrollElementId)) document.getElementById(scrollElementId).scrollIntoView({ behavior: 'smooth' }); }}>
        {children}
    </a>
}

export default NavBtn;