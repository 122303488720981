import * as React from "react";
import TableRow from "./TableRow";

interface Props {
    children: React.ReactNode,
    onClick: React.MouseEventHandler
}

const ClickableTableRow: React.FC<Props> = ({ children, onClick }) => {
        return <TableRow className="cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800"
             onClick={onClick}>
            {children}
        </TableRow>
}

export default ClickableTableRow;