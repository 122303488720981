export { default as AddButtonLabel } from './AddButtonLabel';
export { default as BackButtonLabel } from './BackButtonLabel';
export { default as CancelButtonLabel } from './CancelButtonLabel';
export { default as ConfirmButtonLabel } from "./ConfirmButtonLabel";
export { default as ContactsButtonLabel } from './ContactsButtonLabel';
export { default as CreateButtonLabel } from './CreateButtonLabel';
export { default as DashboardButtonLabel } from "./DashboardButtonLabel";
export { default as DeleteButtonLabel } from './DeleteButtonLabel';
export { default as EditButtonLabel } from './EditButtonLabel';
export { default as InteractionsButtonLabel } from './InteractionsButtonLabel';
export { default as SaveButtonLabel } from './SaveButtonLabel';
export { default as ShowButtonLabel } from './ShowButtonLabel';
export { default as SendMailButtonLabel } from "./SendMailButtonLabel";
export { default as UserButtonLabel } from "./UserButtonLabel";