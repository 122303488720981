import * as React from "react";
// Hooks
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { setModal } from "features/navigation/navigationSlice";
// Utils
import getSelected from "utils/getSelected";
import getPropertyRows from "utils/getPropertyRows";
import getTableRows from "utils/getTableRows";
import { AppNavigator } from "utils/AppNavigator";
// Constants
import { ObjectLayout, adminPath, objectLayoutData } from "../../constants"
// Components
import { LoadingPage } from "components/LoadingPage";
import { AddButtonLabel, BackToTableButton, Button, ButtonGroup, DropdownButton, DropdownButtonItem, EditButtonLabel, Table, TableBody, TableHead, TableHeader, TableItem, TableRow, TableSubHeader } from "components/Elements";



interface ObjectViewProps { }

const ObjectView: React.FC<ObjectViewProps> = ({

}) => {
    // Hooks
    let { objectRoute, guid } = useParams();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let navigator = new AppNavigator(navigate)
    const object = useSelector((state: RootState) => {
        if (state.hasOwnProperty(objectRoute)) {
            return getSelected(state[objectRoute].list, guid)
        } else {
            // TODO: Throw Exception
            return null
        }
    });
    // Configure Data
    const dataLayout: ObjectLayout = objectLayoutData[objectRoute.toLowerCase()];
    /*
     * Primary OnClick Methods
     */
    const onClickBack = () => {
        navigator.list(dataLayout.object_route);
        // navigate(`${adminPath}/${dataLayout.object_route}`);
    }
    const onClickEdit = () => {
        navigator.edit(dataLayout.object_route, guid)
        // navigate(`${adminPath}/${dataLayout.object_route}/edit/${guid}`);
    }
    /*
     * Secondary OnClick Method Generators
     */
    const getOnClickAddSecondary = (dataLayout: ObjectLayout): React.MouseEventHandler => {
        return (event) => dispatch(setModal({
            show: true,
            type: `add-${dataLayout.object_name.toLowerCase()}`,
        }))
    }
    const getOnClickEditSecondary = (dataLayout: ObjectLayout, obj: object): React.MouseEventHandler => {
        return (event) => dispatch(setModal({
            show: true,
            type: `edit-${dataLayout.object_name.toLowerCase()}`,
            object: obj
        }))
    }
    // Render Helpers
    const getAddButton = (onClick: React.MouseEventHandler, objectName: string, ind: number): React.ReactNode => <Button key={`secondary-add-btn-${ind}`} onClick={onClick}>
        <AddButtonLabel objectName={objectName} />
    </Button>
    /*
     * shouldDisplayButtons - Checks if button should be filtered from list of secondary object buttons
     */
    const shouldDisplayButton = (secondaryDataLayout: ObjectLayout) => {
        if (secondaryDataLayout.display_button_filter) {
            return secondaryDataLayout.display_button_filter(object)
        }
        return true
    }
    /*
     * gettAddSecondaryObjectButtons - Render helper for rendering secondary object buttons
     *
     * To Do: Render list as a single button if all but one are filtered
     */
    const getAddSecondaryObjectButtons = (): React.ReactNode => {
        if (dataLayout.secondary_objects != null) {
            if (dataLayout.secondary_objects.length === 1) {
                var secondaryDataLayout = dataLayout.secondary_objects[0]
                // If passes filter or no filter exists, render button
                if (shouldDisplayButton(secondaryDataLayout)) {
                    return getAddButton(getOnClickAddSecondary(secondaryDataLayout), secondaryDataLayout.object_name, 0)
                }
            } else {
                var items = [];
                // Iterate through defined secondary objects
                dataLayout.secondary_objects.map((secondaryDataLayout, ind) => {
                    // If passes filter or no filter exists, add to items
                    if (shouldDisplayButton(secondaryDataLayout)) {
                        items.push(<DropdownButtonItem key={`secondary-add-btn-${ind}`} onClick={getOnClickAddSecondary(secondaryDataLayout)}>
                            <AddButtonLabel objectName={secondaryDataLayout.object_name} />
                        </DropdownButtonItem>)
                    }
                })
                // If items were added to array, render
                if (items.length > 0) {
                    return <DropdownButton dropdownItem={items}>
                        <AddButtonLabel objectName={"Secondary Object"} />
                    </DropdownButton>
                }
            }
        }
        // Else do not render secondary object buttons
        return null;
    }
    /*
     * getSecondaryTables - Render helper for rendering secondary objects when present on the primary object
     */
    const getSecondaryTables = (): Array<React.ReactNode> => {
        // Check if object has secondary objects according to Object Data Layout
        if (dataLayout.secondary_objects != null) {
            var items = [];
            // Iterate through types of secondary objects, rendering them if they exist
            dataLayout.secondary_objects.map((secondary_data, ind) => {
                let obj = secondary_data.object_selector(object)
                // If secondary object is an array of objects - one to many relationship
                if (Array.isArray(obj) && obj.length > 0) {
                    // Secondary Object(s) List
                    items.push(<Table className="table-auto mb-8">
                        <TableSubHeader>{`${secondary_data.object_name}s`}</TableSubHeader>
                        <TableHead headers={secondary_data.table_headers} />
                        <TableBody>
                            {getTableRows(
                                secondary_data,
                                getOnClickEditSecondary(secondary_data, obj),
                                obj
                            )}
                        </TableBody>
                    </Table>)
                // If secondary object is a single object - one to one relationship
                } else if (!Array.isArray(obj) && obj !== null && obj !== undefined) {
                    // Secondary Object Edit Button
                    items.push(<ButtonGroup className="mb-8">
                        <Button onClick={getOnClickEditSecondary(secondary_data, obj)}>
                            <EditButtonLabel objectName={secondary_data.object_name} />
                        </Button>
                    </ButtonGroup>)
                    // Secondary Object Property Table
                    items.push(<Table className="mb-8">
                        <TableHeader>{secondary_data.object_name}</TableHeader>
                        <TableBody>
                            {getPropertyRows(secondary_data, obj)}
                        </TableBody>
                    </Table>)
                } else {
                    return null
                }
            })
            return items;
        }
        return null
    }

    if (object === null)
        return <LoadingPage darkModeEnabled={false} />
    return <div>
        <ButtonGroup className="mb-8">
            <BackToTableButton onClick={onClickBack} />
            <Button onClick={onClickEdit}>
                <EditButtonLabel objectName={dataLayout.object_name} />
            </Button>
            {getAddSecondaryObjectButtons()}
        </ButtonGroup>
        {/* Object Table*/}
        <Table className="mb-8">
            <TableHeader>{dataLayout.object_name}</TableHeader>
            <TableBody>
                {getPropertyRows(dataLayout, object)}
            </TableBody>
        </Table>
        {/* Secondary Object Table(s) */}
        {getSecondaryTables()}
    </div>
}

export default ObjectView;