import * as React from "react";
import ClassBlurb from "./ClassBlurb";
import ClassDivider from "./ClassDivider";
import ClassList from "./ClassList";
import ClassTitle from "./ClassTitle";

interface Props {
    data: {
        title: string,
        blurb: string,
        list?: Array<string>
    }
    renderDivider: boolean
}

const ClassDescription : React.FC<Props> = ({ data, renderDivider }) => {
    return <>
        <ClassTitle>{data.title}</ClassTitle>
        <ClassBlurb>{data.blurb}</ClassBlurb>
        {data.list.length ? <ClassList items={data.list} /> : null}
        {renderDivider ? <ClassDivider /> : null}
    </>
}

export default ClassDescription;