import MichaelProfile from 'images/MichaelProfile.png';

export const classes_header = {
    title: "Online Courses Offered",
    blurb: "Online classes will consist of 3 to 6 students and run for 1 hour twice weekly. Students are placed into age groups 6-8, 9-12, 13-16, and 17-18. We have classes for kids with no coding experience to highly skilled. If you are not sure of the right fit, please contact us and we would be happy to help find an appropriate class",
    pricingText: "Each hour of class is only",
    pricingAmount: "$15.00",
}

// Introductory Classes
export const intro_classes = [
    {
        title: "Age 6-8: Intro to Computer Science",
        blurb: "This course teaches the fundamental concepts of computer science using interactive game based puzzles and a easy to use block based programing language. The course culminates in the creation of the students first game. Students will learn topics such as:",
        list: [
            "Computer and internet safety",
            "Instructions and algorithms",
            "Loops",
            "Events",
            "Animations"
        ]
    },
    {
        title: "Age 9-12: Intro to Computer Science",
        blurb: "This course teaches the fundamental concepts of computer science using interactive game based puzzles and a easy to use block based programing language. The course culminates in the creation of the students first game. Students will learn topics such as:",
        list: [
            "Computer and Internet safety",
            "Instructions and algorithms",
            "Loops and conditionals",
            "Events",
            "Animations"
        ]
    },
    {
        title: "Age 13-16: Intro to Programing",
        blurb: "Students will be introduce to C# and python to learn basic programing concepts. This course will have students utilize and modify example code to familiarize themselves with fundementals of computer science and programing. Students will learn:",
        list: [
            "Structure of a program",
            "Program Flow",
            "Variables",
            "Expressions",
            "Text Inputs and Output"
        ]
    }
]

// Basic Classes
export const basic_classes = [
    {
        title: "Age 6-12: Blockly-I",
        blurb: "Blocky-I will expand on the Intro to Computer Science classes. Students will first be introduced to Scratch and program their own interactive stories, games, and animations. More advance game design will be taught with Makecode Arcade which provides a bridging path into python and text programing",
        list: [
            "Sprites",
            "Program flow",
            "Inputs and Events",
            "Variables",
            "Loops and Conditionals",
            "Functions",
            "Graphics and Animations"
        ]
    },
    {
        title: "Age 9-12: Robotics Jr.",
        blurb: "This course introduces young students to micro-controllers, robotics and physical computing. It introduces physical computing (code and devices that interact with the real world) with an youth friendly micro-controller called the Micro:bit. Micro:bit and a robot add-on will be used to teach students and complete mini project. The microbit can be programed in both blocky and python.",
        list: [
            "What is a Micro-Controller",
            "Program Flow",
            "Inputs and Output",
            "Sensors and Controls",
            "Loops and Conditionals",
            "Events",
            "Functions"
        ]
    },
    {
        title: "Age 9-16: Robotics-I",
        blurb: "Robotics-I introduces young students to micro controllers, robotics and physical computing. In the robotics classes, students learn a combination of programming and electronics. This course is taught using an Arduino simulator allowing students to safely familiarize themselves with circuits and electronics. The Ardiuno uses the C++ programing language to control various devices and sensors.",
        list: [
            "What is a Micro-Controller",
            "Program Flow",
            "Inputs and Output",
            "Sensors and Control",
            "Loops and Conditionals",
            "Interrupts and Functions",
            "Common Electrical Components",
            "Circuits and Electricity",
            "Breadboard and Wiring"
        ]
    },
    {
        title: "Age 9-16: Python-I",
        blurb: "Students will learn to use python and turtle graphics to make animations and simple games. This course will expand on the topics learned in Intro to Programing. In this course student will be expect to writing code on their own.",
        list: [
            "Program Flow",
            "Data Types",
            "Inputs and Events",
            "Loops and Conditionals",
            "Functions",
            "Graphics and Animations"
        ]
    },
    {
        title: "Age 9-16: .NET-I",
        blurb: "Students will use C# and VB.NET to learn the .NETe framework to make applications, apps and simple games. This course will expand on the topics learned in Intro to Programing. In this course student will be expect to writing code on their own.",
        list: [
            "Program Flow",
            "Data Types",
            "Inputs and Events",
            "Loops and Conditionals",
            "Functions",
            "Graphics and Animations"
        ]
    }
]

// Intermediate Classes
export const intermediate_classes = [
    {
        title: "Age 6-12: Blockly-II",
        blurb: "Blocky-II uses Makecode - Minecraft to teach students more advanced concepts of program development by creating add-ons and mods for Minecraft Education Edition. This course will start to introduce students to text based programming (python and javascript) by showing side by side comparison of blocky code and text code.",
        list: []
    },
    {
        title: "Age 12-18: Robotics-II",
        blurb: "Robotics-II builds off what students learned in Robotics-I and introduces them to real hardware. The first half of the course students will continue to learn about physical computing and embedded systems by building several projects. The second half of the course will involve students' own design project. Students will be taught the engineering method and design principles. Students will be required to use these methods and principles in developing their own project.",
        list: []
    },
    {
        title: "Age 12-18: Python II",
        blurb: "Students will learn advanced python concepts and develop games using Makecode - Arcade, Makecode - Minecraft and pyGames. This course will introduce students to Object Oriented Programing (OOP) and the development of stand alone programs.",
        list: [
            "Modules",
            "Collection",
            "Algorithms",
            "Object Oriented Programing",
            "Polymorphism",
            "File I/O"
        ]
    },
    {
        title: "Age 12-18: .NET II",
        blurb: "Students will learn advanced .NETcore concepts to develop desktop and moblie application. This course will introduce students to Object Oriented Programing (OOP) and the development of stand alone programs.",
        list: [
            "Collections",
            "Generics",
            "Object Oriented Programing",
            "Polymorphism",
            "File I/O",
            "Serialization"
        ]
    }
]

// Advanced Classes
export const advanced_classes = [
    {
        title: "Application Development .NET",
        blurb: "Coming Soon.",
        list: []
    },
    {
        title: "Age 12-18: Robotics III",
        blurb: "Coming Soon.",
        list: []
    },
    {
        title: "Age 14-18: Engineering Principles and Design",
        blurb: "This class will have students work on a project of their own selection. They will be taught the engineering method and design principles. Students will use these concepts to complete their project. This course is suited for students interested in problem solving or pursuing a career in engineering.",
        list: []
    }
]

// Team Members
export const team_members = [
    {
        title: "The Instructor",
        name: "Michael Veneziano",
        profile_picture: MichaelProfile,
        blurb: `Michael Veneziano has been mentoring and teaching STEM topics for the past 12 years to students ranging from grade 1 to grade 12. In this time he has taught over 600 students both in classrooms and out.

          He has mentored teams to compete in the FIRST Robotics Program, First Lego League and First Lego League Jr. programs for schools. Michael has tutored topics such as programming, math and robotics to youth and PLC and industrial controls to college engineering students.

        Michael holds a Bachelors Degree of Engineering in Energy Systems and an Electro-Mechanical Engineering Technicians diploma. When not teaching, Michael is the CEO of a technology start-up he founded while in University. His company develops technology for controlled environment agriculture.`
    }
]