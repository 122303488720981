import * as React from "react";
import { ContactObjectLayout, emailRegex, phoneNumberRegex } from "../../constants";
// Features
import { ContactState } from "features/contact/mappers";
// Components
import {
    Form, FormErrorObject
} from "containers/Form"
import { FormField } from "components/Elements";

interface Props {
    initialState: ContactState
    isEdit: boolean,
    isModal: boolean,
    onClickExitHandler: React.MouseEventHandler,
    onClickSaveHandler: (formData: ContactState) => void
}

const ContactForm: React.FC<Props> = ({ initialState, isEdit, isModal, onClickExitHandler, onClickSaveHandler }) => {

    const validateContactForm = (formData: { [key: string]: any }): FormErrorObject => {
        let errorObject: FormErrorObject = {
            error: false,
            message: null
        }
        if (!formData.fname.length) {
            errorObject.error = true
            errorObject.message = "First name is a required field."
            return errorObject
        } else if (!formData.lname.length) {
            errorObject.error = true
            errorObject.message = "Last name is a required field."
            return errorObject
        } else if (!formData.email.match(emailRegex)) {
            errorObject.error = true
            errorObject.message = "Please enter a valid email address."
            return errorObject
        } else if (!formData.pnumber.match(phoneNumberRegex)) {
            errorObject.error = true
            errorObject.message = "Please enter a valid phone number."
            return errorObject
        } else if (formData.status == "other" && !formData.statusOther.length) {
            errorObject.error = true
            errorObject.message = "If status is \"other\", Other Status is a required field."
            return errorObject
        }
        return errorObject
    }

    return <Form
        initialState={initialState}
        isEdit={isEdit}
        isModal={isModal}
        objectName={ContactObjectLayout.object_name}
        onClickExitHandler={onClickExitHandler}
        onClickSaveHandler={onClickSaveHandler}
        validate={validateContactForm}
    >
        <FormField name={"fname"} label={"First Name"} is={"input"} />
        <FormField name={"lname"} label={"Last Name"} is={"input"} />
        <FormField name={"email"} label={"Email"} is={"input"} type={"email"} />
        <FormField name={"pnumber"} label={"Phone Number"} is={"input"} type={"tel"} />
        <FormField name={"line1"} label={"Address Line 1"} is={"input"} />
        <FormField name={"line2"} label={"Address Line 2"} is={"input"} />
        <FormField name={"line3"} label={"Address Line 3"} is={"input"} />
        <FormField name={"city"} label={"City"} is={"input"} />
        <FormField name={"province"} label={"Province"} is={"input"} />
        <FormField name={"postalCode"} label={"Postal Code"} is={"input"} />
        <FormField name={"country"} label={"Country"} is={"input"} />
        <FormField name={"status"} label={"Status"} is={"select"} options={ContactObjectLayout.value_options.status} otherable={true} />
    </Form>
}

export default ContactForm;