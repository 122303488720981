import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const TableItem : React.FC<Props> = ({ children, className = "" }) => {
    return <td className={`px-6 py-4 ${className}`}>
        {children}
    </td>
}

export default TableItem;