import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const ListItem : React.FC<Props> = ({ children, className = "" }) => {
    return <li className="text-base md:text-lg lg:text-2xl ml-0 md:ml-4 lg:ml-8">
        {children}
    </li>
}

export default ListItem;