import * as React from 'react';

import 'styles/LandingPage.css';

import { MainNavBar } from './MainNavBar';
import { ClassesSection } from './ClassesSection';
import { AboutSection } from './AboutSection';
import { ContactSection } from './ContactSection'
import FooterSection from './FooterSection';
import HeaderSection from './HeaderSection';

export default function LandingPage() {
    return <main id="home" className="landing-page">
        <div className="PageGrid">
            <MainNavBar />
            <HeaderSection />
            <ClassesSection />
            <AboutSection />
            <ContactSection />
            <FooterSection />
        </div>
    </main>
}