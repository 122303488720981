import { Interaction } from "features/interaction/interactionsSlice";
import { Contact } from "features/contact/contactsSlice";
import { Address } from "features/commonEntities";
import { User } from "../features/user/usersSlice";

export const instanceOfEmptyArray = (object: object): object is Array<any> => {
    return Array.isArray(object) && object.length == 0;
}

export const instanceOfStringArray = (object: object): object is Array<string> => {
    if (Array.isArray(object) && object.length != 0) {
        return typeof object[0] === "string"
    }
}

export const instanceOfInteraction = (object: object): object is Interaction => {
    return object.hasOwnProperty("followUpDate");
}

export const instanceOfInteractionsArray = (object: object): object is Array<Interaction> => {
    if (Array.isArray(object) && object.length) {
		if (object[0] !== null) return instanceOfInteraction(object[0]);
    }
	return false
}

export const instanceOfContact = (object: object): object is Contact => {
    return object.hasOwnProperty("interactions");
}

export const instanceOfUser = (object: object): object is User => {
    return object.hasOwnProperty("userName");
}

export const instanceOfAddress = (object: object): object is Address => {
    return object.hasOwnProperty("postalCode");
}