import * as React from "react";

interface Props {
    darkModeEnabled?: boolean,
    fullScreen?: boolean
}

const LoadingPage: React.FC<Props> = ({ darkModeEnabled = true, fullScreen = true }) => <div className={`${fullScreen ? "h-screen" : "h-96"} w-full flex items-center justify-center ${darkModeEnabled ? "dark:bg-slate-900" : ""}`}>
    <div
        className={`inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${darkModeEnabled ? "text-slate-900 dark:text-white" : ""}`}
        role="status"
    >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
        </span>
    </div>
</div>

export default LoadingPage;