import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const Header : React.FC<Props> = ({ children, className = "" }) => {
    return <h2 className={`text-2xl md:text-4xl lg:text-6xl ${className}`}>{children}</h2>
}

export default Header;