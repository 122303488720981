import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Contact } from '../contact/contactsSlice'
import { Interaction } from '../interaction/interactionsSlice'

export type ModalType = "edit-contact" | "add-interaction" | "edit-interaction" | string | null
export type ModalObject = Contact | Interaction | object | null
export type ConfirmationType = null

interface NavigationState {
    modalShow: boolean,
    modalType: ModalType,
    modalObject: ModalObject,
    confirmationShow: boolean,
    confirmationType: ConfirmationType
}

interface SetModalTypePayload {
    type: ModalType
}

interface SetModalPayload {
    show: boolean,
    type: ModalType,
    object?: ModalObject 
}

interface SetConfirmationPayload {
    show: boolean,
    type: ConfirmationType
}

const initialState: NavigationState = {
    modalShow: false,
    modalType: null,
    modalObject: null,
    confirmationShow: false,
    confirmationType: null
}

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setModalShow: (state, action: PayloadAction<boolean>) => {
            state.modalShow = action.payload;
        },
        setModalType: (state, action: PayloadAction<ModalType>) => {
            state.modalType = action.payload.replace(/\s+/g, '-').toLowerCase();
        },
        setModalObject: (state, action: PayloadAction<ModalObject>) => {
            state.modalObject = action.payload;
        },
        setModal: (state, action: PayloadAction<SetModalPayload>) => {
            state.modalShow = action.payload.show;
            state.modalType = action.payload.type.replace(/\s+/g, '-').toLowerCase();
            state.modalObject = action.payload.hasOwnProperty("object") ? action.payload.object : null;
        },
        clearModal: (state) => {
            state.modalShow = false;
            state.modalType = null;
            state.modalObject = null;
        },
        setConfirmationShow: (state, action: PayloadAction<boolean>) => {
            state.confirmationShow = action.payload;
        },
        setConfirmationType: (state, action: PayloadAction<ConfirmationType>) => {
            state.confirmationType = action.payload;
        },
        setConfirmation: (state, action: PayloadAction<SetConfirmationPayload>) => {
            state.confirmationShow = action.payload.show;
            state.confirmationType = action.payload.type;
        },
        clearConfirmation: (state) => {
            state.confirmationShow = false;
            state.confirmationType = null;
        }
    }
})

// Actions
export const { setModalShow, setModalType, setModal, clearModal, setConfirmationShow, setConfirmationType, setConfirmation, clearConfirmation } = navigationSlice.actions;

export default navigationSlice.reducer;