import * as React from "react";
import { navLinkClasses } from "./constants";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps {
    toggle: boolean,
    setToggle: React.Dispatch<React.SetStateAction<boolean>>
}

const NavToggle : React.FC<Props> = ({ className="", children, toggle, setToggle }) => {
    return <a className={`block md:hidden ${navLinkClasses} ${className}`}
        onClick={() => setToggle(!toggle)}>
        {children}
    </a>
}

export default NavToggle;