import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const ButtonGroup: React.FC<Props> = ({ children, className = "" }) => {
    var classes = `inline-flex w-full space-x-2 mb-4` +
        // ` grid xl:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2` +
        // ` justify-items-center gap-4` +
        ` ${className}`
    return <div className={classes}>
        {children}
    </div>
}

export default ButtonGroup;