import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const Content : React.FC<Props> = ({ children, className = "" }) => {
    return <p className={`text-base md:text-lg lg:text-2xl whitespace-pre-line ${className}`}>
        {children}
    </p>
}

export default Content;