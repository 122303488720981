import * as React from "react";
import IChildrenProps from "props/IChildrenProps";

interface Props extends IChildrenProps { }

const ClassGrid: React.FC<Props> = ({ children }) => {
    return <div className="container pb-8 border-2 border-white bg-white">
        {children}
    </div>
}

export default ClassGrid;