import React from "react";
import { TableItem, TableRow } from "components/Elements";
import { valueToRenderable } from "./toRenderable";
import { ObjectLayout } from "../constants";

const getPropertyRows = (layout: ObjectLayout, object: object): Array<React.ReactNode> => {
	var items = [];
	for (const [key, value] of Object.entries(object)) {
		var renderable = valueToRenderable(value)
		if (renderable != null) {
			items.push(<TableRow className="uppercase" key={`object-view-table-row-${key}`}>
				<TableItem key={`${key}`}>{key}</TableItem>
				<TableItem key={`${key}-value`}>{renderable}</TableItem>
			</TableRow>)
		}
	}
	return items;
}

export default getPropertyRows;