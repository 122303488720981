import * as React from "react";

interface Props {
    submitted: boolean,
    errored: boolean,
    errorMsg: string
}

const FormAlert: React.FC<Props> = ({ submitted, errored, errorMsg }) => {
    if (submitted)
        return <strong className="w-full p-4 mt-6 t-alert t-success-alert">Your contact message has been sent!</strong>
    else if (errored)
        return <strong className="w-full p-4 mt-6 t-alert t-error-alert">{errorMsg != "" ? errorMsg : "An error has occurred. Please try again."}</strong>
    return null
}

export default FormAlert;