import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IOnClickProps from "props/IOnClickProps";
import { Link } from "react-router-dom";

interface Props extends IChildrenProps {
    href: string,
}

const SideBarButton: React.FC<Props> = ({ children, href }) => {
    return <li>
        <Link
            to={href}
            className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
        >
            {children}
        </Link>
    </li>
}

export default SideBarButton;