import * as React from "react"
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";
import IOnClickOptionalProps from "props/IOnClickOptionalProps";

interface Props extends IChildrenProps, IExtensibleClassProps, IOnClickOptionalProps { 

}

const DropdownButtonItem: React.FC<Props> = ({ children, className = "", onClick }) => <li>
    <a onClick={onClick} className={`flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full cursor-pointer ${className}`}>{children}</a>
</li>

export default DropdownButtonItem;