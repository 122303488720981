import { Contact } from "../features/contact/contactsSlice"
import { Interaction } from "../features/interaction/interactionsSlice"
import { User } from "../features/user/usersSlice"

export const spoofContacts: Array<Contact> = [{
    id: "3",
    created: "2023-07-31 12:12:12",
    fname: "John",
    lname: "Doe",
    email: "fakegmail@gmail.com",
    pnumber: "(416) 765-4321",
    address: {
        line1: "123 Fake Street",
        line2: "",
        line3: "",
        city: "Toronto",
        province: "Ontario",
        postalCode: "A1B 2C3",
        country: "Canada"
    },
    status: "lead",
    interactions: [
        {
            id: "3001",
            date: '2023/06/16',
            comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
            origin: 'What is "Origin?"',
            followUpDate: '2023-07-02',
            followUpComplete: false
        }
    ]
}, {
    id: "4",
    created: "2023-07-31 12:12:12",
    fname: "John",
    lname: "Doe",
    email: "fakegmail@gmail.com",
    pnumber: "(416) 765-4321",
    address: {
        line1: "123 Fake Street",
        line2: "",
        line3: "",
        city: "Toronto", 
        province: "Ontario",
        postalCode: "A1B 2C3",
        country: "Canada"
    },
    status: "lead",
    interactions: [
        {
            id: "4001",
            date: '2023/06/16',
            comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
            origin: 'What is "Origin?"',
            followUpDate: '2023/07/02',
            followUpComplete: false
        }
    ]
}, {
    "id": "5",
    created: "2023-07-31 12:12:12",
    fname: "John",
    lname: "Doe",
    email: "fakegmail@gmail.com",
    pnumber: "(416) 765-4321",
    address: {
        line1: "123 Fake Street",
        line2: "",
        line3: "",
        city: "Toronto", 
        province: "Ontario",
        postalCode: "A1B 2C3",
        country: "Canada"
    },
    status: "prospect",
    interactions: [
        {
            id: "5001",
            date: '2023/06/16',
            comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
            origin: 'What is "Origin?"',
            followUpDate: '2023/07/02',
            followUpComplete: false
        }
    ]
}, {
    id: "6",
    created: "2023-07-31 12:12:12",
    fname: "John",
    lname: "Doe",
    email: "fakegmail@gmail.com",
    pnumber: "(416) 765-4321",
    address: {
        line1: "123 Fake Street",
        line2: "",
        line3: "",
        city: "Toronto", 
        province: "Ontario",
        postalCode: "A1B 2C3",
        country: "Canada"
    },
    status: "prospect",
    interactions: [
        {
            id: "6001",
            date: '2023/06/16',
            comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
            origin: 'What is "Origin?"',
            followUpDate: '2023/07/02',
            followUpComplete: false
        }
    ]
},
{
    id: "7",
    created: "2023-07-31 12:12:12",
    fname: "John",
    lname: "Doe",
    email: "fakegmail@gmail.com",
    pnumber: "(416) 765-4321",
    address: {
        line1: "123 Fake Street",
        line2: "",
        line3: "",
        city: "Toronto", 
        province: "Ontario",
        postalCode: "A1B 2C3",
        country: "Canada"
    },
    status: "prospect",
    interactions: [
        {
            id: "7001",
            date: '2023/06/16',
            comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
            origin: 'What is "Origin?"',
            followUpDate: '2023/07/02',
            followUpComplete: false
        }
    ]
}, {
    id: "8",
    created: "2023-07-31 12:12:12",
    fname: "John",
    lname: "Doe",
    email: "fakegmail@gmail.com",
    pnumber: "(416) 765-4321",
    address: {
        line1: "123 Fake Street",
        line2: "",
        line3: "",
        city: "Toronto", 
        province: "Ontario",
        postalCode: "A1B 2C3",
        country: "Canada"
    },
    status: "prospect",
    interactions: [
        {
            id: "8001",
            date: '2023/06/16',
            comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
            origin: 'What is "Origin?"',
            followUpDate: '2023/07/02',
            followUpComplete: false
        }
    ]
}, {
    id: "1",
    created: "2023-07-31 12:12:12",
    fname: "John",
    lname: "Doe",
    email: "fakegmail@gmail.com",
    pnumber: "(416) 765-4321",
    address: {
        line1: "123 Fake Street",
        line2: "",
        line3: "",
        city: "Toronto", 
        province: "Ontario",
        postalCode: "A1B 2C3",
        country: "Canada"
    },
    status: "converted",
    interactions: [
        {
            id: "1001",
            date: '2023/06/01',
            comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
            origin: 'What is "Origin?"',
            followUpDate: '2023/06/16',
            followUpComplete: true
        }
    ]
}, {
    id: "2",
    created: "2023-07-31 12:12:12",
    fname: "John",
    lname: "Doe",
    email: "fakegmail@gmail.com",
    pnumber: "(416) 765-4321",
    address: {
        line1: "123 Fake Street",
        line2: "",
        line3: "",
        city: "Toronto", 
        province: "Ontario",
        postalCode: "A1B 2C3",
        country: "Canada"
    },
    status: "declined",
    interactions: [
        {
            id: "2001",
            date: '2023/06/16',
            comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
            origin: 'What is "Origin?"',
            followUpDate: '2023/07/02',
            followUpComplete: true
        }
    ]
}]

export const spoofInteractions: Array<Interaction> = [
    {
        contactId: "3",
        "contact": {
            id: "3",
            fname: 'John',
            lname: 'Doe',
            email: "fakegmail@gmail.com",
            pnumber: "(416) 765-4321",
            address: {
                line1: "123 Fake Street",
                line2: "",
                line3: "",
                city: "Toronto",
                province: "Ontario",
                postalCode: "A1B 2C3",
                country: "Canada"
            },
            status: "declined"
        },
        id: "3001",
        date: '2023-06-16',
        comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
        origin: 'contactSubmission',
        followUpDate: '2023-07-02',
        followUpComplete: false
    }, {
        contactId: "4",
        "contact": {
            id: "4",
            fname: 'John',
            lname: 'Doe',
            email: "fakegmail@gmail.com",
            pnumber: "(416) 765-4321",
            address: {
                line1: "123 Fake Street",
                line2: "",
                line3: "",
                city: "Toronto",
                province: "Ontario",
                postalCode: "A1B 2C3",
                country: "Canada"
            },
            status: "declined"
        },
        id: "4001",
        date: '2023-06-16',
        comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
        origin: 'phoneCall',
        followUpDate: '2023-07-02',
        followUpComplete: false
    }, {
        contactId: "5",
        "contact": {
            id: "5",
            fname: 'John',
            lname: 'Doe',
            email: "fakegmail@gmail.com",
            pnumber: "(416) 765-4321",
            address: {
                line1: "123 Fake Street",
                line2: "",
                line3: "",
                city: "Toronto",
                province: "Ontario",
                postalCode: "A1B 2C3",
                country: "Canada"
            },
            status: "declined"
        },
        id: "5001",
        date: '2023-06-16',
        comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
        origin: 'email',
        followUpDate: '2023-07-02',
        followUpComplete: false
    }, {
        contactId: "6",
        "contact": {
            id: "6",
            fname: 'John',
            lname: 'Doe',
            email: "fakegmail@gmail.com",
            pnumber: "(416) 765-4321",
            address: {
                line1: "123 Fake Street",
                line2: "",
                line3: "",
                city: "Toronto",
                province: "Ontario",
                postalCode: "A1B 2C3",
                country: "Canada"
            },
            status: "declined"
        },
        id: "6001",
        date: '2023-06-16',
        comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
        origin: 'What is "Origin?"',
        followUpDate: '2023-07-02',
        followUpComplete: false
    }, {
        contactId: "7",
        "contact": {
            id: "7",
            fname: 'John',
            lname: 'Doe',
            email: "fakegmail@gmail.com",
            pnumber: "(416) 765-4321",
            address: {
                line1: "123 Fake Street",
                line2: "",
                line3: "",
                city: "Toronto",
                province: "Ontario",
                postalCode: "A1B 2C3",
                country: "Canada"
            },
            status: "declined"
        },
        id: "7001",
        date: '2023-06-16',
        comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
        origin: 'What is "Origin?"',
        followUpDate: '2023-07-02',
        followUpComplete: false
    }
    , {
        contactId: "8",
        "contact": {
            id: "8",
            fname: 'John',
            lname: 'Doe',
            email: "fakegmail@gmail.com",
            pnumber: "(416) 765-4321",
            address: {
                line1: "123 Fake Street",
                line2: "",
                line3: "",
                city: "Toronto",
                province: "Ontario",
                postalCode: "A1B 2C3",
                country: "Canada"
            },
            status: "declined"
        },
        id: "8001",
        date: '2023-06-16',
        comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
        origin: 'What is "Origin?"',
        followUpDate: '2023-07-02',
        followUpComplete: false
    }, {
        contactId: "1",
        "contact": {
            id: "1",
            fname: 'John',
            lname: 'Doe',
            email: "fakegmail@gmail.com",
            pnumber: "(416) 765-4321",
            address: {
                line1: "123 Fake Street",
                line2: "",
                line3: "",
                city: "Toronto",
                province: "Ontario",
                postalCode: "A1B 2C3",
                country: "Canada"
            },
            status: "declined"
        },
        id: "1001",
        date: '2023-06-01',
        comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
        origin: 'What is "Origin?"',
        followUpDate: '2023-06-16',
        followUpComplete: true
    },
    {
        contactId: "2",
        "contact": {
            id: "2",
            fname: 'John',
            lname: 'Doe',
            email: "fakegmail@gmail.com",
            pnumber: "(416) 765-4321",
            address: {
                line1: "123 Fake Street",
                line2: "",
                line3: "",
                city: "Toronto",
                province: "Ontario",
                postalCode: "A1B 2C3",
                country: "Canada"
            },
            status: "declined"
        },
        id: "2001",
        date: '2023-06-01',
        comment: `This is fake data. The data is fake. This comment is therefore fake, too. How can our comments be real if our eyes aren't real? They can't, they're fake.`,
        origin: 'What is "Origin?"',
        followUpDate: '2023-06-16',
        followUpComplete: true
    }
]

export const spoofUsers: Array<User> = [
    {
        roles: ["Administrator"],
        address: {
            line1: "123 Fake Street",
            line2: "",
            line3: "",
            city: "Toronto",
            province: "Ontario",
            postalCode: "A1B 2C3",
            country: "Canada"
        },
        fname: "Admin",
        lname: "Spoof",
        id: "abcdefgh-1234-abcd-1234-abcd-123456789abc",
        userName: "Administrator",
        email: "admin.spoof@example.com",
        emailConfirmed: false,
        phoneNumber: "",
        phoneNumberConfirmed: false,
        twoFactorEnabled: false,
        parentProfile: null,
        studentProfile: null
    },
    {
        roles: ["Parent"],
        address: {
            line1: "123 Fake Street",
            line2: "",
            line3: "",
            city: "Toronto",
            province: "Ontario",
            postalCode: "A1B 2C3",
            country: "Canada"
        },
        fname: "Parental",
        lname: "Spoof",
        id: "abcdefgh-4321-abcd-4321-abcd-123456789abc",
        userName: "ParentPerson",
        email: "parenty.spoof@example.com",
        emailConfirmed: false,
        phoneNumber: "",
        phoneNumberConfirmed: false,
        twoFactorEnabled: false,
        parentProfile: {
            id: "hgfedcba-1234-abcd-1234-abcd-123456789abc",
            userId: "abcdefgh-4321-abcd-4321-abcd-123456789abc",
            referrer: null,
            referrerId: null,
            children: [],
            referrals: [],
            coParents: []
        },
        studentProfile: null
    },
    {
        roles: ["Parent"],
        address: {
            line1: "123 Fake Street",
            line2: "",
            line3: "",
            city: "Toronto",
            province: "Ontario",
            postalCode: "A1B 2C3",
            country: "Canada"
        },
        fname: "Spoofy",
        lname: "Parent",
        id: "abcdefgh-9876-abcd-9876-abcd-123456789abc",
        userName: "ParentPerson",
        email: "spoofy.parent@example.com",
        emailConfirmed: false,
        phoneNumber: "",
        phoneNumberConfirmed: false,
        twoFactorEnabled: false,
        parentProfile: {
            id: "hgfedcba-6789-abcd-6789-abcd-123456789abc",
            userId: "abcdefgh-9876-abcd-9876-abcd-123456789abc",
            referrer: null,
            referrerId: null,
            children: [],
            referrals: [],
            coParents: []
        },
        studentProfile: null
    }
]