import * as React from "react";
import { SubHeader } from "components/Elements";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const TableSubHeader : React.FC<Props> = ({ children, className = "" }) => {
    return <thead className={`text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ${className}`}>
        <tr>
            <th className="px-6 py-3" colSpan={10}>
                <SubHeader>{children}</SubHeader>
            </th>
        </tr>
    </thead>
}

export default TableSubHeader;