import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";
import IOnClickProps from "props/IOnClickProps";
import DropdownButtonItem from "./DropdownButtonItem";
import { IconDown } from "..";

interface Props extends IChildrenProps, IExtensibleClassProps {
    colour?: string,
    type?: "button" | "submit" | "reset",
    dropdownItem: React.ReactNode
}

const Button: React.FC<Props> = ({ children, className = "", colour = "gray", dropdownItem, type = "button" }) => {
    const [show, setShow] = React.useState(false)
    return <>
        <button type={type} className={`flex items-center shadow font-bold text-${colour}-900 dark:text-white bg-white hover:bg-gray-50 focus:ring-gray-500 dark:bg-${colour}-500 dark:hover:bg-${colour}-400 dark:focus:ring-gray-800 py-2 px-4 rounded ${className}`}
            onClick={() => setShow(!show)}>
            {children} <IconDown />
        </button>
        {show && <div id="dropdown" className="z-10 divide-y divide-gray-100 shadow w-44 relative">
            <ul className="py-2 text-sm bg-gray-200 dark:bg-gray-500 text-gray-700 dark:text-white shadow absolute divide-y divide-gray-100 shadow w-full">
                {dropdownItem}
            </ul>
        </div>}
    </>
}

export default Button; 