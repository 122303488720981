import * as React from "react";
import IExtensibleClassProps from "props/IExtensibleClassProps";
import IFormTextAreaProps from "props/IFormTextAreaProps";

interface Props extends IExtensibleClassProps, IFormTextAreaProps { }

const TextArea: React.FC<Props> = ({ className = "", disabled = false, name, onChange, rows = 4, value }) => <textarea
    className={`mb-4 p-1 border-2 w-full resize-none ${className}`}
    name={name}
    onChange={onChange}
    value={value}
    rows={rows}
    disabled={disabled}
/>

export default TextArea