import * as React from "react";
import { connect } from "react-redux";
import { setToken } from "features/authentication/authenticationSlice";
import { RootState } from "app/store";
import { ResultsCallback, ErrorCallback, FinalCallback, useApiContext } from "containers/ApiContext/ApiContext";
import { useNavigate, useSearchParams } from "react-router-dom";

interface Props {
    setToken: (token: string) => void,
    label: string,
    redirect: string
}

const LoginPage: React.FC<Props> = ({ label, redirect, setToken }) => {
    // Navigation
    let navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams()
    // API
    const { client } = useApiContext();
    // Local State
	const [canSubmit, setCanSubmit] = React.useState(true)
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")

    const resCallback: ResultsCallback = (res: any) => {
        setToken(res.data);
        let next = searchParams.get("next")
        if (next)
            navigate(next)
        else
            navigate(redirect)
	}
    const errCallback: ErrorCallback = (err: any) => alert(err.message)
	const finalCallback: FinalCallback = () => setCanSubmit(true)

    const onSubmitHandler: React.MouseEventHandler = (event: React.MouseEvent) => {
        setCanSubmit(false);
        client.authenticate({ username, password }, resCallback, errCallback, finalCallback);
    }

    return <div className="container mx-auto">
        <div className="text-center m-8">
            <form className="w-full max-w-xs mx-auto">
                <h1 className="mb-4">Tech Start Kids - {label}</h1>
                <input
                    className="mb-4 p-1 border-2"
					disabled={!canSubmit}
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder={"Username"}
                />
                <input
                    className="mb-4 p-1 border-2"
					disabled={!canSubmit}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={"Password"}
                />
                <div className="flex justify-center">
                    <button
						disabled={!canSubmit}
                        type="button"
                        className="shadow bg-blue-500 hover:bg-blue-400 text-white py-1 px-2 rounded disabled:opacity-25"
                        onClick={onSubmitHandler}
                    >
                        Log In
                    </button>
                </div>
            </form>
        </div>
    </div>
}

const mapStateToProps = (state: RootState) => ({ })

export default connect(mapStateToProps, { setToken: setToken })(LoginPage);