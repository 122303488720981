import * as React from "react";
import { Section, SectionContentWrapper, SectionHeader } from "components/Elements"
import TeamMemberDescription from "./TeamMemberDescription";
import { team_members } from "components/LandingPage/data";


const AboutSection: React.FC = () => {
    return <Section className="" id="about">
        <SectionContentWrapper>
            <SectionHeader>About</SectionHeader>
            {team_members.map((member, ind) => <TeamMemberDescription key={`team-member-${ind}`} index={ind} {...member} />)}
        </SectionContentWrapper>
    </Section>
}

export default AboutSection;