import { User, Parent, Student } from "./usersSlice";
import { UserObjectLayout } from "../../constants"
import { Address } from "../commonEntities";
import convertBoolSelect from "utils/convertBoolSelect";

/*
 * Form Data States
 */
export type UserState = {
    fname: string,
    lname: string,
    userName: string,
    email: string,
    emailConfirmed: string,
    phoneNumber: string,
    phoneNumberConfirmed: string,
    twoFactorEnabled: string,
    // Address
    line1: string,
    line2: string,
    line3: string,
    city: string,
    province: string,
    postalCode: string,
    country: string,
}

export type UserDTO = {
    address: Address,
    fname: string,
    lname: string,
    id?: string,
    userName: string,
    email: string,
    emailConfirmed: boolean,
    phoneNumber: string,
    phoneNumberConfirmed: boolean,
    twoFactorEnabled: boolean,
}

export type ParentState = {
    referrerId: string
}

export type ParentDTO = {
    referrerId: string
}

export type StudentState = {

}

/*
 * User Form Data Mappers
 */
export function getInitialUserState(): UserState {
    return {
        line1: "",
        line2: "",
        line3: "",
        city: "",
        province: "",
        postalCode: "",
        country: "",
        fname: "",
        lname: "",
        userName: "",
        email: "",
        emailConfirmed: "",
        phoneNumber: "",
        phoneNumberConfirmed: "",
        twoFactorEnabled: "",
    }
}

export function mapUserToState(user: User): UserState {
    return {
        fname: user.fname,
        lname: user.lname,
        userName: user.userName,
        email: user.email,
        emailConfirmed: user.emailConfirmed ? "yes" : "no",
        phoneNumber: user.phoneNumber === undefined ? "" : user.phoneNumber,
        phoneNumberConfirmed: user.phoneNumberConfirmed ? "yes" : "no",
        twoFactorEnabled: user.twoFactorEnabled ? "yes" : "no",
        line1: user.address.line1,
        line2: user.address.line2,
        line3: user.address.line3,
        city: user.address.city,
        province: user.address.province,
        postalCode: user.address.postalCode,
        country: user.address.country,
    }    
}

export function mapStateToUserDTO(state: UserState): UserDTO {
    return {
        fname: state.fname,
        lname: state.lname,
        userName: state.userName,
        email: state.email,
        emailConfirmed: convertBoolSelect(state.emailConfirmed),
        phoneNumber: state.phoneNumber,
        phoneNumberConfirmed: convertBoolSelect(state.phoneNumberConfirmed),
        twoFactorEnabled: convertBoolSelect(state.twoFactorEnabled),
        address: {
            line1: state.line1,
            line2: state.line2,
            line3: state.line3,
            city: state.city,
            province: state.province,
            postalCode: state.postalCode,
            country: state.country,
        }
    }
}

/*
 * Parent Profile Form Data Mappers
 */

export function getInitialParentState(): ParentState {
    return {
        referrerId: ""
    }
}

export function mapParentToState(parent: Parent): ParentState {
	console.warn("mapParentToState", parent)
    return {
        referrerId: parent.referrerId
    }
}

export function mapStateToParentDTO(state: ParentState): ParentDTO {
    return {
        referrerId: state.referrerId
    }
}