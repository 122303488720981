import * as React from "react";
import Button from "./Button";
import IOnClickProps from "props/IOnClickProps";
import { ButtonGroup, BackButtonLabel } from "components/Elements";

interface Props extends IOnClickProps { }

const BackToTableButton : React.FC<Props> = ({ onClick }) => {
    return <Button className="items-center" onClick={onClick}>
        <BackButtonLabel pageName={"Table"} />
    </Button>
}

// onClick={() => dispatch(actionGenerator())}

export default BackToTableButton;