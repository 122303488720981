import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Interaction } from "../interaction/interactionsSlice";
import { Address } from "../commonEntities";

export type Contact = {
    id?: string,
    created?: string,
    fname: string,
    lname: string,
    email: string,
    pnumber: string,
    address: Address,
    status: string
    interactions?: Array<Interaction> | null
}

interface ContactsState {
    list:  Array<Contact> | null
}

const initialState : ContactsState = {
    list: null
}

export const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        loadContacts: (state, action: PayloadAction<Array<Contact>>) => {
            state.list = action.payload;
        }
    }
})

// Actions
export const { loadContacts } = contactsSlice.actions;

export default contactsSlice.reducer