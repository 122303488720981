import * as React from "react";
import { IconAddFill } from "components/Elements";
import ILabelObjectNameProps from "./props/ILabelObjectNameProps";

interface Props extends ILabelObjectNameProps { }

const AddButtonLabel : React.FC<Props> = ({ objectName }) => {
    return <><IconAddFill className="mr-2" /> Add {objectName}</>
}

export default AddButtonLabel;