import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";
import IOnClickProps from "props/IOnClickProps";

interface Props extends IChildrenProps, IExtensibleClassProps, IOnClickProps {
    colour?: string,
    type?: "button" | "submit" | "reset"
}

const Button: React.FC<Props> = ({ children, className = "", colour = "gray", onClick, type = "button" }) => {
    return <button type={type} className={`flex items-center shadow font-bold text-${colour}-900 dark:text-white bg-white hover:bg-gray-50 dark:bg-${colour}-500 dark:hover:bg-${colour}-400 py-2 px-4 rounded ${className}`}
        onClick={onClick}>
        {children}
    </button>
}

export default Button;