import { Contact } from "./features/contact/contactsSlice";
import { Interaction } from "./features/interaction/interactionsSlice";
import { Parent, Student, User } from "./features/user/usersSlice";

// API
export const apiUri = process.env.REACT_API_ENDPOINT;
// Navigation
export const landingPath = ""
export const loginPath = "/login"
export const parentsPath = "/parents"
export const adminPath = "/admin"
// - Authentication
export const authEndpoint = "/security"
export const tokenEndpoint = "/token"
export const checkTokenEndpoint = "/check-token"
// - Contacts
export const contactEndpoint = "/contacts"
export const contactSubmissionEndpoint = "/contacts/submission"
// - Interaction
export const interactionEndpoint = "/interactions"
// Validation
export const phoneNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const dateRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
export const guidRegex = /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/;

/*
 * Names are Haaaaaaaaaaaaaaaaaaard
 */
export type ObjectLayoutType = {
    object_name: string,
    object_route: string,
    table_headers: Array<string>,
    key_filters: Array<string>,
    value_options: {
        [key: string]: Array<string>
    },
    secondary_objects: Array<ObjectLayoutType>
    object_selector?: (obj: object) => Array<object> | object | null,
    display_button_filter?: (obj: object) => boolean
}

/*
 * Object Layout Data
 */
export interface ObjectLayout extends ObjectLayoutType {
    // Secondary Objects
    
}

/*
 * Contact
 */
export const ContactObjectLayout: ObjectLayoutType = {
    // Primary Object
    object_name: 'Contact',
    object_route: 'contacts',
    table_headers: ["Created", "Fname", "Lname", "Email", "Phone Number", "Address", "Status", "Next Interaction"],
    key_filters: ["id"],
    value_options: {
        status: ["lead", "propsect", "converted", "declined", "other"]
    },
    secondary_objects: null
}

/*
 * Interaction
 */
export const InteractionObjectLayout: ObjectLayoutType = {
    object_name: 'Interaction',
    object_route: 'interactions',
    table_headers: ["Date", "Comment", "Origin", "Follow Up Date", "Follow Up Complete"],
    key_filters: ["id", "contactId"],
    value_options: {
        origin: ["contactSubmission", "phone", "email", "referral", "other"],
        followUpComplete: ["yes", "no"]
    },
    secondary_objects: null
}

/*
 * User, Parent Profile, CoParent Profile, Referral Profile, Student Profile, Child Profile
 */
export const UserObjectLayout: ObjectLayoutType = {
    object_name: 'User',
    object_route: 'users',
    table_headers: ["Roles", "Address", "Fname", "Lname", "UserName", "Email", "PhoneNumber", "EmailConfirmed", "PhoneNumberConfirmed", "TwoFactorEnabled",],
    key_filters: ["id", "parentProfile", "studentProfile"],
    value_options: {
        emailConfirmed: ["yes", "no"],
        phoneNumberConfirmed: ["yes", "no"],
        twoFactorEnabled: ["yes", "no"]
    },
    secondary_objects: null
}

export const ParentProfileObjectLayout: ObjectLayoutType = {
    object_name: 'Parent Profile',
    object_route: 'parents',
    table_headers: ["Children", "Referrals", "CoParents"],
    key_filters: ["id", "userId"],
    value_options: {},
    secondary_objects: null
}

export const CoParentProfileObjectLayout: ObjectLayoutType = {
    ...ParentProfileObjectLayout,
    object_name: 'CoParent',
    object_route: 'coparents'
}

export const ReferralProfileObjectLayout: ObjectLayoutType = {
    ...ParentProfileObjectLayout,
    object_name: 'Referral',
    object_route: 'referrals'
}

export const StudentProfileObjectLayout: ObjectLayoutType = {
    object_name: 'Student Profile',
    object_route: 'students',
    table_headers: [],
    key_filters: [],
    value_options: {},
    secondary_objects: null
}

export const ChildProfileObjectLayout: ObjectLayoutType = {
    ...StudentProfileObjectLayout,
    object_name: 'Children',
    object_route: 'children'
}

/*
 * Object Layouts with Secondary Objects
 */
export const ContactObjectLayoutWithSecondaryObjects: ObjectLayoutType = {
    ...ContactObjectLayout,
    secondary_objects: [{
        ...InteractionObjectLayout,
        object_selector: (contact: Contact): Array<Interaction> => contact.interactions
    }]
}

export const InteractionObjectLayoutWithSecondaryObjects: ObjectLayoutType = {
    ...InteractionObjectLayout,
    secondary_objects: [{
        ...ContactObjectLayout,
        object_selector: (interaction: Interaction): Contact => interaction.contact
    }]
}

/*
 * User Object and Secondary Objects
 */

const ParentProfileSecondaryObjectLayout = {
    ...ParentProfileObjectLayout,
    object_selector: (user: User): Parent | null => user.parentProfile,
    display_button_filter: (user: User): boolean => user.parentProfile === null || user.parentProfile === undefined
}

const CoParentProfileSecondaryObjectLayout = {
    ...CoParentProfileObjectLayout,
    object_selector: (user: User): Array<Parent> | null => user.parentProfile === null || user.parentProfile === undefined
        ? null : user.parentProfile.coParents,
    // display_button_filter: (user: User): boolean => user.parentProfile !== null && user.parentProfile !== undefined
    display_button_filter: (user: User): boolean => false
}

const ReferralProfileSecondaryObjectLayout = {
    ...ReferralProfileObjectLayout,
    object_selector: (user: User): Array<Parent> | null => user.parentProfile === null || user.parentProfile === undefined
        ? null : user.parentProfile.referrals,
    // display_button_filter: (user: User): boolean => user.parentProfile !== null && user.parentProfile !== undefined
    display_button_filter: (user: User): boolean => false
}

const ChildProfileSecondaryObjectLayout = {
    ...ChildProfileObjectLayout,
    object_selector: (user: User): Array<Student> | null => user.parentProfile === null || user.parentProfile === undefined
        ? null : user.parentProfile.children,
    // display_button_filter: (user: User): boolean => user.parentProfile !== null && user.parentProfile !== undefined
    display_button_filter: (user: User): boolean => false
}

const StudentProfileSecondaryObjectLayout = {
    ...StudentProfileObjectLayout,
    object_selector: (user: User): Student | null => user.studentProfile,
    // display_button_filter: (user: User): boolean => user.studentProfile === null || user.studentProfile === undefined
    display_button_filter: (user: User): boolean => false
}

export const UserObjectLayoutWithSecondaryObjects: ObjectLayoutType = {
    ...UserObjectLayout,
    secondary_objects: [
        ParentProfileSecondaryObjectLayout,
        CoParentProfileSecondaryObjectLayout,
        ReferralProfileSecondaryObjectLayout,
        ChildProfileSecondaryObjectLayout,
        StudentProfileSecondaryObjectLayout
    ]
}

export const ParentProfileObjectLayoutWithSecondaryObjects: ObjectLayoutType = {
    ...ParentProfileObjectLayout,
    secondary_objects: [
        CoParentProfileSecondaryObjectLayout,
        ReferralProfileSecondaryObjectLayout,
        ChildProfileSecondaryObjectLayout
    ]
}

/*
 * Object Layout Data
 */
export type ObjectLayoutData = {
    contacts: ObjectLayout,
    interactions: ObjectLayout,
    users: ObjectLayout
}

export const objectLayoutData: ObjectLayoutData = {
    contacts: ContactObjectLayoutWithSecondaryObjects,
    interactions: InteractionObjectLayoutWithSecondaryObjects,
    users: UserObjectLayoutWithSecondaryObjects
}