import * as React from "react";
import IChildrenProps from "props/IChildrenProps";

interface Props extends IChildrenProps {
    index: number,
    tab: number,
    setTab: React.Dispatch<React.SetStateAction<number>>
}

const ClassTab: React.FC<Props> = ({ children, index, tab, setTab }) => {
    const isActiveClass  = tab == index ? " active" : ""
    const gridAreaNumber = index + 1
    const onClick = () => setTab(index)

    return <button
        className={`items-center justify-center cursor-pointer select-none w-full border-2 border-white text-white custom-tab text-lg md:text-xl p-8 ${isActiveClass}`}
        style={{ "gridArea": `item${gridAreaNumber}` }}
        onClick={onClick}>
            {children}
    </button>
}

export default ClassTab;