import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const TableWrapper : React.FC<Props> = ({ children, className = "" }) => {
    return <div className={`w-full ${className}`}>
        {children}
    </div>
}

export default TableWrapper;