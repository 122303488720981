import * as React from "react";
import IChildrenProps from "props/IChildrenProps";

interface Props extends IChildrenProps { }

const ContactForm: React.FC<Props> = ({ children }) => {
    return <form className="flex flex-col items-center w-full text-lg md:text-2xl">
        {children}
    </form>
}

export default ContactForm;