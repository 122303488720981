import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const Header : React.FC<Props> = ({ children, className = "" }) => {
    return <p className={`uppercase text-base md:text-lg lg:text-xl py-4 w-full dark:bg-gray-600 dark:text-white ${className}`}>{children}</p>
}

export default Header;