import * as React from "react";
import IExtensibleClassProps from "props/IExtensibleClassProps"
import IFormLabelProps from "props/IFormLabelProps";

interface Props extends IExtensibleClassProps, IFormLabelProps { }

const Label: React.FC<Props> = ({ className = "", label, name }) => <label
    className={`uppercase dark:text-white ${className}`}
    htmlFor={name}
>
    {label || name.split(/(?=[A-Z])/).join(" ")}
</label>

export default Label;