import { NavigateFunction } from "react-router-dom";
import { ContactObjectLayout, InteractionObjectLayout, UserObjectLayout, loginPath } from "../constants";

export interface AppNavigatorInterface {
    navigate: NavigateFunction
}

export class AppNavigator implements AppNavigatorInterface {
    navigate: NavigateFunction
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate
    }

    // Private
    public list = (objectRoute: string) => this.navigate(`/admin/${objectRoute}`)
    public add  = (objectRoute: string) => this.navigate(`/admin/${objectRoute}/add`)
    public view = (objectRoute: string, guid: string) => this.navigate(`/admin/${objectRoute}/view/${guid}`)
    public edit = (objectRoute: string, guid: string) => this.navigate(`/admin/${objectRoute}/edit/${guid}`)

    // Authentication
    public login = (path: string, next?: string) => this.navigate(next !== undefined ? `${path}${loginPath}?next=${next}` : `${path}${loginPath}`)

    // Contact
    public contactList = () => this.list(ContactObjectLayout.object_route)
    public contactAdd = () => this.add(ContactObjectLayout.object_route)
    public contactView = (guid: string) => this.view(ContactObjectLayout.object_route, guid)
    public contactEdit = (guid: string) => this.edit(ContactObjectLayout.object_route, guid)

    // Interaction
    public interactionList = () => this.list(InteractionObjectLayout.object_route)
    public interactionAdd = () => this.add(InteractionObjectLayout.object_route)
    public interactionView = (guid: string) => this.view(InteractionObjectLayout.object_route, guid)
    public interactionEdit = (guid: string) => this.edit(InteractionObjectLayout.object_route, guid)

    // User
    public userList = () => this.list(UserObjectLayout.object_route)
    public userAdd = () => this.add(UserObjectLayout.object_route)
    public userView = (guid: string) => this.view(UserObjectLayout.object_route, guid)
    public userEdit = (guid: string) => this.edit(UserObjectLayout.object_route, guid)
}