import * as React from "react";
import IChildrenProps from "props/IChildrenProps";

interface Props extends IChildrenProps {
    index: number,
    tab: number
}

const ClassContent: React.FC<Props> = ({ children, index, tab }) => {
    return <div className={`m-8 ${index == tab ? "block" : "hidden"}`}>
        {children}
    </div>
}

export default ClassContent;