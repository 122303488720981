import * as React from "react";
import axios from "axios";
import { apiUri, contactSubmissionEndpoint, emailRegex, phoneNumberRegex } from "../../../constants";
import { Section, SectionContentWrapper, SectionHeader } from "components/Elements";
import { ContactForm, ContactFormAlert, ContactFormInput, ContactFormSubmitButton, ContactFormTextArea } from "./ContactForm";
import ReCAPTCHA from "react-google-recaptcha"

const ContactSection: React.FC = () => {
    // Constants
    const submitLabel = "SEND MESSAGE"
    const sitekey = "6LeDZhIhAAAAAPFJvprtOWv4Zev3nE_Epkmtt6vo"
    // Local State
    const [fname, setFname] = React.useState("")
    const [lname, setLname] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [pnumber, setPnumber] = React.useState("")
    const [comment, setComment] = React.useState("")
    // ReCAPTCHA
    const [verified, setVerified] = React.useState(false)
    // Alerts
    const [submitted, setSubmitted] = React.useState(false)
    const [errored, setErrored] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState("")
    // Helper Methods
    const getOnChange = (setter: React.SetStateAction<any>) => {
        return (event) => setter((event.target as HTMLInputElement).value)
    }
    // Helper Methods
    const createFormData = () => ({
        fname: fname,
        lname: lname,
        email: email,
        pnumber: pnumber,
        comment: comment
    })
    const setError = (bool: boolean, msg: string) => {
        setErrorMsg(msg);
        setErrored(bool);
    }
    const resetError = () => {
        setError(false, "");
    }
    // Event Handlers
    const onSubmit = (event: React.MouseEvent) => {
        resetError();

        if (!fname.length) {
            setError(true, "First name is a required field.")
        } else if (!lname.length) {
            setError(true, "Last name is a required field.")
        } else if (!email.match(emailRegex)) {
            setError(true, "Please enter a valid email address.")
        } else if (!pnumber.match(phoneNumberRegex)) {
            setError(true, "Please enter a valid phone number.")
        } else if (verified) {
            const formData = createFormData();

            axios.post(`${apiUri}${contactSubmissionEndpoint}`, formData)
                .then((res) => {
                    setSubmitted(true);
                })
                .catch((error) => {
					console.error(error)
                    setError(true, "An error has occurred. We were unable to submit your contact form.")
                })
        } else {
            setError(true, "Please verify with ReCAPTCHA before submitting form.");
        }

    }
    const onCaptcha = (value) => {
        if (value) {
            setVerified(value)
            return
        }
        setVerified(false)
    }

    return <Section className="text-center bg-primary-blue flex-col" id="contact">
        <SectionContentWrapper>
            <SectionHeader className="text-white">Contact</SectionHeader>
            <ContactForm>
                {!submitted && <React.Fragment>
                    <ContactFormInput name="fname" placeholder="First Name" value={fname} onChange={getOnChange(setFname)} />
                    <ContactFormInput name="lname" placeholder="Last Name" value={lname} onChange={getOnChange(setLname)} />
                    <ContactFormInput name="email" placeholder="Email" value={email} onChange={getOnChange(setEmail)} />
                    <ContactFormInput name="pnumber" placeholder="Phone Number" value={pnumber} onChange={getOnChange(setPnumber)} />
                    <ContactFormTextArea name="comment" placeholder="Message / Special Requirements" value={comment} onChange={getOnChange(setComment)} />
                    <ReCAPTCHA
                        sitekey={sitekey}
                        onChange={onCaptcha}
                    />
                </React.Fragment>}
                <ContactFormAlert submitted={submitted} errored={errored} errorMsg={errorMsg} />
                <ContactFormSubmitButton onClick={onSubmit} disabled={submitted}>
                    {submitLabel}
                </ContactFormSubmitButton>
            </ContactForm>
        </SectionContentWrapper>
    </Section>
}

export default ContactSection;