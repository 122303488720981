// TODO: Better mapping strategy
import { InteractionObjectLayout } from "../../constants"
import isSelectOther from "../../utils/isSelectOther"
import { Interaction } from "./interactionsSlice"

const originValueOptions = InteractionObjectLayout.value_options.origin;

export type InteractionState = {
	contactId?: string,
    date: string,
    origin: string,
    originOther: string,
    comment: string,
    followUpDate: string,
    followUpComplete: string
}

export function getInitialInteractionState(): InteractionState {
    return {
        date: "",
        origin: "",
        originOther: "",
        comment: "",
        followUpDate: "",
        followUpComplete: "no"
    }
}

export function mapInteractionToState(interaction: Interaction): InteractionState {
    var boolSelectOther = isSelectOther(originValueOptions, interaction.origin)
    return {
		contactId: interaction.contactId,
        date: interaction.date,
        origin: boolSelectOther ? "other" : interaction.origin,
        originOther: boolSelectOther ? interaction.origin : "",
        comment: interaction.comment,
        followUpDate: interaction.followUpDate,
        followUpComplete: interaction.followUpComplete ? "yes" : "no"
    }
}

export function mapStateToInteraction(state: InteractionState): Interaction {
    var boolSelectOther = isSelectOther(originValueOptions, state.origin)
    return {
		contactId: state.hasOwnProperty("contactId") ? state.contactId : null,
        date: state.date,
        origin: boolSelectOther ? state.originOther : state.origin,
        comment: state.comment,
        followUpDate: state.followUpDate,
        followUpComplete: state.followUpComplete == "yes" ? true : false
    }
}