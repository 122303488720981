import * as React from "react";
import { IconShow } from "components/Elements";
import ILabelObjectNameProps from "./props/ILabelObjectNameProps";

interface Props extends ILabelObjectNameProps { }

const ShowButtonLabel : React.FC<Props> = ({ objectName }) => {
    return <><IconShow className="mr-2" /> Show {objectName}</>
}

export default ShowButtonLabel;