import * as React from "react";
import IChildrenProps from "props/IChildrenProps";

interface Props extends IChildrenProps {
    onClick: React.MouseEventHandler,
    disabled: boolean
}

const FormSubmitButton: React.FC<Props> = ({ children, disabled, onClick }) => {
    return <button className={`w-full py-4 px-2 m-2 rounded ${disabled ? "bg-gray-500" : "bg-white hover:bg-gray-200"}`} type="button" onClick={onClick} disabled={disabled}>
        {children}
    </button>
}

export default FormSubmitButton;