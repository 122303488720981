import isSelectOther from "utils/isSelectOther"
import { Contact } from "./contactsSlice"
import { ContactObjectLayout } from "../../constants"

const statusValueOptions = ContactObjectLayout.value_options.status;

export type ContactState = {
    fname: string,
    lname: string,
    email: string,
    pnumber: string,
    line1: string,
    line2: string,
    line3: string,
    city: string,
    province: string,
    postalCode: string,
    country: string
    status: string,
    statusOther: string
}

export function getInitialContactState(): ContactState {
    return {
        fname: "",
        lname: "",
        email: "",
        pnumber: "",
        line1: "",
        line2: "",
        line3: "",
        city: "",
        province: "",
        postalCode: "",
        country: "",
        status: "",
        statusOther: ""
    }
}

export function mapContactToState(contact: Contact): ContactState {
    var boolSelectOther = isSelectOther(statusValueOptions, contact.status);
    return {
        fname: contact.fname,
        lname: contact.lname,
        email: contact.email,
        pnumber: contact.pnumber,
        line1: contact.address.line1,
        line2: contact.address.line2,
        line3: contact.address.line3,
        city: contact.address.city,
        province: contact.address.province,
        postalCode: contact.address.postalCode,
        country: contact.address.country,
        status: boolSelectOther ? "other" : contact.status,
        statusOther: boolSelectOther ? contact.status : ""
    }
}

export function mapStateToContact(state: ContactState): Contact {
    var boolSelectOther = isSelectOther(statusValueOptions, state.status);
    return {
        fname: state.fname,
        lname: state.lname,
        email: state.email,
        pnumber: state.pnumber,
        address: {
            line1: state.line1,
            line2: state.line2,
            line3: state.line3,
            city: state.city,
            province: state.province,
            postalCode: state.postalCode,
            country: state.country
        },
        status: boolSelectOther ? state.statusOther : state.status
    }
}