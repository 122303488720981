import * as React from "react"
import { User } from "../../features/user/usersSlice"
import { Button, ButtonGroup, CancelButtonLabel, ConfirmButtonLabel, FormAlert, FormTextArea, Header, SendMailButtonLabel } from "../../components/Elements"
import { LoadingPage } from "../../components/LoadingPage"
import createOnChange from "../../utils/createOnChange"
import { connect } from "react-redux"
import { RootState } from "../../app/store"
import { useApiClient } from "../ApiContext"


interface Props {
    users: Array<User>
}

export type RecipientObject = {
    name: string,
    email: string,
    checked: boolean
}

export type RecipientDict = {
    [key: string]: RecipientObject
}

export type MassMailObject = {
    subject: string,
    message: string,
    recipients: RecipientDict
}

export type MassMailPostData = {
    subject: string,
    message: string,
    recipients: Array<RecipientObject>
}

const SendMassMailPage: React.FC<Props> = ({ users }) => {
    const client = useApiClient();
    // Form Data
    const [formData, setFormData] = React.useState({
        recipients: {},
        subject: "",
        message: ""
    });
    // Alerts
    const [submitted, setSubmitted] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [errored, setErrored] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const setError = (bool: boolean, msg: string) => {
        setErrorMsg(msg);
        setErrored(bool);
    }
    const resetError = () => {
        setError(false, "");
    }
    // Get list of users that can be sent to and initialize form data
    const initializeFormData = () => {
        let userDict = {}
        users.map((user, ind) => {
            if (user.email) {
                userDict[user.email] = {
                    email: user.email,
                    name: `${user.fname} ${user.lname}`,
                    checked: false
                };
            }
        })

        setFormData({
            recipients: userDict,
            subject: "",
            message: ""
        });
    }
    React.useEffect(() => initializeFormData(), [users])
    // Event Handlers
    const onClickSubmit = (event) => {
        resetError()
        if (formData.message.length < 10) {
            setError(true, "Please set a message to be sent to the selected users before submitting.")
        } if (formData.subject.length < 5) {
            setError(true, "Please set a subject to be used for the email before submitting.")
        } else {
            setSubmitted(true);
        }
    }
    const onClickSend = (event) => {
        setConfirmed(true);
        let recipients: Array<RecipientObject> = Object.values(formData.recipients as RecipientDict).filter(r => r.checked)
        var postData: MassMailPostData = {
            recipients: recipients,
            subject: formData.subject,
            message: formData.message
        }
        setTimeout(() => { console.log(postData); }, 2000);
        setSent(true);
    }
    const onClickReset = (event) => {
        initializeFormData();
        setSubmitted(false);
        setConfirmed(false);
        setSent(false);
        resetError();
    }
    const onChange = (e: React.ChangeEvent<Element>) => {
        const { name, value } = e.target as HTMLInputElement
        if (name === "subject" || name === "message") {
            setFormData({ ...formData, [name]: value })
        } else {
            let recipients = formData.recipients as RecipientDict;
            let recipient = recipients[name] as RecipientObject // Name refers to the name of the input, email refers to the email of the recipient

            recipients[name].checked = !recipient.checked
            setFormData({
                ...formData,
                recipients: recipients
            })
        }
    }
    // Render
    if (users === null || users.length === 0 || Object.keys(formData.recipients).length === 0)
        return <LoadingPage darkModeEnabled={false} />
    return <div>
        <form>
            <div>
                <Header>
                    Send Mass Email
                </Header>
                {users.map((user, ind) => {
                    if (user.email) {
                        return <div className="flex items-center">
                            <input
                                className="mr-4"
                                name={user.email}
                                value={formData.recipients[user.email].checked}
                                checked={formData.recipients[user.email].checked}
                                type={"checkbox"}
                                onChange={onChange}
                                disabled={submitted}
                            />
                            <label className="uppercase">
                                {`${user.fname} ${user.lname} - ${user.email}`}
                            </label>
                        </div>
                    }
                })}
                <label className="uppercase">
                    Subject
                </label>
                <input
                    className="w-full border-2"
                    name={"subject"}
                    value={formData.subject}
                    onChange={onChange}
                    disabled={submitted}
                />
                <label className="uppercase">
                    Message Contents
                </label>
                <FormTextArea
                    name={"message"}
                    value={formData.message}
                    rows={10}
                    onChange={onChange}
                    disabled={submitted}
                />
                <FormAlert submitted={sent} errored={errored} errorMsg={errorMsg} />
                <ButtonGroup>
                    {!submitted &&
                        <>
                            <Button colour={"green"} onClick={onClickSubmit}>
                                <SendMailButtonLabel pageName={"Send"} />
                            </Button>
                            <Button colour={"red"} onClick={onClickReset}>
                                <CancelButtonLabel />
                            </Button>
                        </>}
                    {(submitted && !confirmed) &&
                        <>
                            <Button colour={"green"} onClick={onClickSend}>
                                <ConfirmButtonLabel />
                            </Button>
                            <Button colour={"red"} onClick={onClickReset}>
                                <CancelButtonLabel />
                            </Button>
                        </>}
                    {(submitted && confirmed) &&
                        <Button colour={"blue"} onClick={onClickReset}>
                            <SendMailButtonLabel pageName={"Reset"} />
                        </Button>}
                </ButtonGroup>
            </div>
        </form>
    </div>
}

const mapStateToProps = (state: RootState) => ({
    users: state.users.list
})

export default connect(mapStateToProps, {})(SendMassMailPage);