import * as React from "react";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IExtensibleClassProps {
    headers: Array<string>
}

const TableHead : React.FC<Props> = ({ className = "", headers }) => {
    return <thead className={`text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ${className}`}>
        <tr>
            {headers.map((h) => <th className="px-6 py-3" key={h}>{h}</th>)}
        </tr>
    </thead>
}

export default TableHead;