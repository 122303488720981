import * as React from "react";
import IExtensibleClassProps from "props/IExtensibleClassProps";
import IOnClickProps from "props/IOnClickProps";
import { IconCancel } from "components/Elements";

interface Props extends IExtensibleClassProps, IOnClickProps {}

const ExitButton : React.FC<Props> = ({ className = "", onClick }) => {
    return <button className={`float-right text-lg md:text-2xl lg:text-4xl dark:text-gray-400 ${className}`} onClick={onClick}>
        <IconCancel />
    </button>
}

export default ExitButton;