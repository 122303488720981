import * as React from "react";
import { UserObjectLayout } from "../../constants";
// Features
import { ParentState } from "features/user/mappers";
// Components
import {
    Form, FormErrorObject
} from "containers/Form"
import { FormField } from "components/Elements";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useParams } from "react-router-dom";

interface Props {
    initialState: ParentState
    isEdit: boolean,
    isModal: boolean,
    onClickExitHandler: React.MouseEventHandler,
    onClickSaveHandler: (formData: ParentState) => void
}

const ParentForm: React.FC<Props> = ({ initialState, isEdit, isModal, onClickExitHandler, onClickSaveHandler }) => {
    const { guid } = useParams();
    const users = useSelector((state: RootState) => state.users.list)

    let userOptions = []
    let referrerOptions = []
    users.map((user, ind) => {
        if (user.parentProfile === null || user.parentProfile === undefined) {
            userOptions.push({
                value: user.id,
                label: `${user.fname} ${user.lname} ${user.id}`
            })
        } else if (user.id !== guid) {
            referrerOptions.push({
                value: user.parentProfile.id,
                label: `${user.fname} ${user.lname} ${user.parentProfile.id}`
            })
        }
    })

    const validateParentForm = (formData: { [key: string]: any }): FormErrorObject => {
        let errorObject: FormErrorObject = {
            error: false,
            message: null
        }

        if (formData.referrerId === "") {
            errorObject.error = true;
            errorObject.message = "You must select a user to associate this parent profile with."
        }

        return errorObject
    }

    return <Form
        initialState={initialState}
        isEdit={isEdit}
        isModal={isModal}
        objectName={UserObjectLayout.object_name}
        onClickExitHandler={onClickExitHandler}
        onClickSaveHandler={onClickSaveHandler}
        validate={validateParentForm}
    >
        <FormField name={"referrerId"} label={"Referrer"} is={"select"} options={referrerOptions} />
    </Form>
}

export default ParentForm;