import * as React from "react";

interface Props {
    children: React.ReactNode,
    className?: string
}

const Header: React.FC<Props> = ({ children, className = "" }) => {
    return <h1 className={`text-lg md:text-xl lg:text-2xl ${className}`}>
        {children}
    </h1>
}

export default Header;