import * as React from "react";
import { SectionHeader, SectionContent } from "components/Elements";

interface Props {
    title: string,
    blurb: string,
    pricingText: string,
    pricingAmount: string
}

const ClassesHeader : React.FC<Props> = ({ title, blurb, pricingText, pricingAmount }) => {
    return <div className="text-center">
        <SectionHeader className="text-white mb-8">{title}</SectionHeader>
        <SectionContent className="text-white text-center mb-8">
            {blurb}
        </SectionContent>
        <SectionContent className="text-white text-center mb-8">
            {pricingText} <span className="text-orange-highlight font-bold">{pricingAmount}</span>
        </SectionContent>
    </div>
}

export default ClassesHeader;