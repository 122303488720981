import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import { SectionSubHeader } from "components/Elements";

interface Props extends IChildrenProps { }

const ClassTitle : React.FC<Props> = ({ children }) => {
    return <SectionSubHeader>{children}</SectionSubHeader>
}

export default ClassTitle;