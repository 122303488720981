import * as React from "react";
import { SectionList, SectionListItem } from "components/Elements";

interface Props {
    items: Array<string>
}

const ClassList : React.FC<Props> = ({ items }) => {
    return <SectionList>
        {items.map((item, index) => <SectionListItem className="text-lg md:text-2xl" key={item + index}>{item}</SectionListItem>)}
    </SectionList>
}

export default ClassList;