import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Address } from "../commonEntities";

export type Parent = {
    id?: string,
    userId: string,
    referrer: Parent | null,
    referrerId: string | null,
    children: Array<Student>,
    referrals: Array<Parent>,
    coParents: Array<Parent>
}

export type Student = {

}

export type User = {
    roles: Array<string>,
    address: Address,
    fname: string,
    lname: string,
    id?: string,
    userName: string,
    email: string,
    emailConfirmed: boolean,
    phoneNumber: string,
    phoneNumberConfirmed: boolean,
    twoFactorEnabled: boolean,
    parentProfile?: Parent | null,
    studentProfile?: Student | null
}

interface UsersState {
    list: Array<User> | null
}

const initialState: UsersState = {
    list: null
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        loadUsers: (state, action: PayloadAction<Array<User>>) => {
            state.list = action.payload;
        }
    }
})

// Actions
export const { loadUsers } = usersSlice.actions;

export default usersSlice.reducer