import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";
import IOnClickOptionalProps from "props/IOnClickOptionalProps";

interface Props extends IChildrenProps, IExtensibleClassProps, IOnClickOptionalProps { }

const TableRow : React.FC<Props> = ({ children, className = "", onClick }) => {
    if (onClick) return <tr className={`bg-white border-b dark:bg-gray-600 dark:border-gray-500 dark:text-white ${className}`} onClick={onClick}>{children}</tr>
    else return <tr className={`bg-white border-b dark:bg-gray-600 dark:border-gray-500 dark:text-white ${className}`}>{children}</tr>
}

export default TableRow;