import * as React from 'react';
import { createRoot } from 'react-dom/client';
// Constants
import { adminPath, apiUri, landingPath, parentsPath, loginPath } from './src/constants';
// Contaext
import { Provider } from 'react-redux';
import { store } from "app/store"
import { ApiContext, createApiClient } from 'containers/ApiContext';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import { LandingPage } from 'components/LandingPage';
import { ConnectedApp, SpoofConnectedApp } from 'containers/ConnectedApp';
import { ConnectedAdminPanel, SpoofAdminPanel } from 'containers/AdminPanel'
import { LoginPage } from 'containers/LoginPage';
import { ObjectForm, ObjectTable, ObjectView } from 'containers/ObjectLayouts';
import { SendMassMailPage } from './src/containers/SendMassMailPage';
import { Dashboard } from './src/components/Dashboard';


export const App: React.FC = () => {
	const client = createApiClient({
		baseURL: apiUri
	})
	
    return <Provider store={store}>
		<React.StrictMode>
			<ApiContext.Provider value={{ client }}>
				<CookiesProvider>
					<BrowserRouter>
						<Routes>
							<Route path={landingPath} element={<LandingPage />} />
							{/* Parent Portal */}
							<Route path={`${parentsPath}${loginPath}`} element={<LoginPage label={"Parents Portal"} redirect={parentsPath} />} />
							<Route path={parentsPath} element={<SpoofConnectedApp path={parentsPath} />}>
								<Route path="" element={<></>} />
							</Route>
							{/* Admin Panel */}
							<Route path={`${adminPath}${loginPath}`} element={<LoginPage label={"Admin Panel"} redirect={adminPath} />} />
							<Route path={adminPath} element={<ConnectedApp path={adminPath} />}>
								<Route path="" element={<ConnectedAdminPanel />}>
									<Route path="" element={<Dashboard />} />
									{/* Utils and Specific Paths */}
									<Route path={`send-mass-mail`} element={<SendMassMailPage />} />
									{/* Object Handlers */}
									<Route path=":objectRoute/view/:guid" element={<ObjectView />} />
									<Route path=":objectRoute/edit/:guid" element={<ObjectForm mode={"edit"} />} />
									<Route path=":objectRoute/add" element={<ObjectForm mode={"add"} />} />
									<Route path=":objectRoute/:mode" element={<>Mode</>} />
									<Route path=":objectRoute" element={<ObjectTable />} />
								</Route>
							</Route>
						</Routes>
					</BrowserRouter>
				</CookiesProvider>
			</ApiContext.Provider>
		</React.StrictMode>
	</Provider>
}



const root = createRoot(document.getElementById('root'))
root.render(<App />)