import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import { IconArrowLeft, IconArrowRight } from "components/Elements";

interface Props extends IChildrenProps { }

const SideBar: React.FC<Props> = ({ children }) => {
    const [expand, setExpand] = React.useState(false);

    return <aside className="fixed top-o left-0 z-20 w-64 h-screen">
        <button
            className="px-4 py-4 left-0 bottom-0 fixed overflow-y-auto bg-gray-50 text-black dark:text-white dark:bg-gray-800 text-white block lg:hidden transition-transform"
            onClick={() => setExpand(!expand)}
        >
            {expand ? <IconArrowLeft /> : <IconArrowRight />}
        </button>
        <div className={`h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800  transition-transform ${expand ? "block" : "hidden lg:block"}`}>
            <div className="mt-8 pt-8">
                <ul className="space-y-2 font-medium">
                    {children}
                </ul>
            </div>
        </div>
    </aside>
}

export default SideBar;