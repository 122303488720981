import * as React from "react";

interface Props {
    option: string,
    label?: string
}

const SelectOption: React.FC<Props> = ({ option, label = null }) => {
    return <option className="uppercase" value={option}>
        {label !== null ? label : option.split(/(?=[A-Z])/).join(" ")}
    </option>
}

export default SelectOption;