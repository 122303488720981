import React from "react"
import { connect, useDispatch } from "react-redux"
import { AppNavigator } from "utils/AppNavigator"
// Constants
import { adminPath, loginPath } from "../../constants"
// Context
import { RootState } from "app/store"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useApiClient } from "containers/ApiContext"
import { useCookies } from "react-cookie"
// Features
import { setToken } from "features/authentication/authenticationSlice"
// Components
import { LoadingPage } from "components/LoadingPage"
import { NavBar } from "components/Elements"
// For Testing
import { spoofContacts, spoofInteractions, spoofUsers } from "app/spoofs"
import { loadContacts } from "features/contact/contactsSlice"
import { loadInteractions } from "features/interaction/interactionsSlice"
import { loadUsers } from "features/user/usersSlice"

export interface ConnectedAppStateProps {
	token: string | null
}

export interface ConnectedAppProps extends ConnectedAppStateProps {
	path: string
}

const loginURI = `${adminPath}${loginPath}`
const tokenExpiryInMinutes = 60

const ConnectedApp: React.FC<ConnectedAppProps> = ({ path, token }) => {
	/* 
	 * Flag to determine if validation should be occurring or not
	 * - value: bool
	 * - defaultValue: false
	 */
	const [validating, setValidating] = React.useState(false)
	/*
	 * Flag to indicate whether token is valid or not
	 * - value: bool | null
	 * - defaultValue: null
	 */
	const [valid, setValid] = React.useState(null)
	// Hooks
	// - Redux
	const dispatch = useDispatch()
	// - ApiContext
	const client = useApiClient()
	// - React-Router-Dom
	const location = useLocation()
	const navigator = new AppNavigator(useNavigate())
	const loginRedirect = () => navigator.login(path, location.pathname)
	// - Cookies
	const [cookies, setCookie, removeCookie] = useCookies()
	const cookieToken = cookies['token'] !== undefined ? cookies['token'] : null
	const setCookieToken = (token: string): void => {
		let date = new Date()
		date.setTime(date.getTime() + (tokenExpiryInMinutes*60*1000))
		setCookie('token', token, {
			sameSite: true,
			expires: date
		})
	}
	
	/*
	 * Token Check
	 *
	 * Checks to see if token exists in store. 
	 * - If token exists, checks if the user is on the login page. 
	 * - If the user is on the login page, redirects to admin panel, otherwise begins token validation.
	 * If token does not exist, check cookie token. 
	 * - If cookie token exists, set token to cookie token and re-trigger effect.
	 * If cookie token also does not exist, check if the user is on the login page. 
	 * - If the user is on the login page, render the page. 
	 * - Otherwise, redirect to login page.
	 */
	const [test] = React.useState(false)
	React.useEffect(() => {
		if (token !== null) {
			setValidating(true)
		} else if (cookieToken !== null) {
			dispatch(setToken(cookieToken))
		} else {
			loginRedirect()
		}
	}, [token])

	/*
	 * Check Token Validity
	 *
	 * Check to see if validation has been initiated.
	 * - If validation has been initated, validate token with the API
	 */
	React.useEffect(() => {
		if (validating) {
			// Handle if token is valid and authentication check succeeds.
			let ctResCallback = (res) => {
				setValid(true)
			}
			// Handle if token is invalid and authentication check fails.
			let ctErrCallback = (err) => {
				setValid(false)
			}
			// End validation
			let ctFinCallback = () => {
				setValidating(false)
			}
			// Trigger API Call
			client.checkToken(ctResCallback, ctErrCallback, ctFinCallback)
		}
	}, [validating])


	/*
	 * Handle Validation Results
	 * - If token is valid, begin loading data
	 * - If token is invalid, set token to null and redirect to Login Page
	 */
	React.useEffect(() => {
		if (valid !== null) {
			if (valid) {
				setCookieToken(token)
			} else {
				removeCookie('token')
				setToken(null)
				loginRedirect()
			}
		}
	}, [valid])

	
	if (valid) {
		return <div>
			<NavBar />
			<Outlet />
		</div>
	} else {
		return <LoadingPage />
	}
}

const mapAppStateToProps = (state: RootState): ConnectedAppStateProps => ({
	token: state.authentication.token
})


export default connect(mapAppStateToProps, {})(ConnectedApp);

/* ---------------------------------------------------------------------------------------------------- */

const SpoofApp: React.FC<ConnectedAppProps> = ({ path, token }) => {
	let dispatch = useDispatch();
	dispatch(loadContacts(spoofContacts));
	dispatch(loadInteractions(spoofInteractions));
	dispatch(loadUsers(spoofUsers))

	return <div>
		<NavBar />
		<Outlet />
	</div>
}

const mapSpoofStateToProps = (state: RootState): ConnectedAppStateProps => ({
	token: state.authentication.token
})


export const SpoofConnectedApp = connect(mapSpoofStateToProps, {})(SpoofApp);

/* ---------------------------------------------------------------------------------------------------- */