import React from "react";
import { TableItem } from "components/Elements";
import { keyValuePairToRenderable } from "./toRenderable";
import { ObjectLayout } from "../constants";


const getTableItems = (data: ObjectLayout, obj: object, ind: number) => {
    var items = [];
    for (const [key, value] of Object.entries(obj)) {
        var renderable = keyValuePairToRenderable(key, value, data.key_filters)
        if (renderable != null) {
            items.push(<TableItem
                className="uppercase"
                key={`object-table-item-${key}-${ind}`}
            >
                {renderable}
            </TableItem>)
        }
    }
    return items;
}

export default getTableItems;