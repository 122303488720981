import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const SubHeader: React.FC<Props> = ({ children, className = "" }) => {
    return <h2 className={`text-base md:text-lg lg:text=xl ${className}`}>
        {children}
    </h2>
}

export default SubHeader;