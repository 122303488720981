import * as React from "react";
import NavBtn from "./NavBtn";
import NavToggle from "./NavToggle";
import NavToggleSVG from "./NavToggleSVG";

const MainNavBar: React.FC = () => {
    const [toggle, setToggle] = React.useState(false)

    return <nav className="flex top-0 left-0 flex-col md:flex-row justify-start md:justify-left items-start md:items-center m-0 pl-2 md:pl-0 fixed bg-navbar-blue opacity-75 z-50 w-full">
        <NavToggle toggle={toggle} setToggle={setToggle}><NavToggleSVG /></NavToggle>
        <NavBtn scrollElementId={"home"} toggle={toggle}>HOME</NavBtn>
        <NavBtn className={"text-orange-highlight"} scrollElementId={"classes"} toggle={toggle}>CLASSES</NavBtn>
        <NavBtn scrollElementId={"about"} toggle={toggle}>ABOUT</NavBtn>
        <NavBtn scrollElementId={"contact"} toggle={toggle}>CONTACT</NavBtn>
    </nav>
}

export default MainNavBar;