import * as React from "react";

interface Props {
    children: React.ReactNode,
    className?: string
}

const Wrapper: React.FC<Props> = ({ children, className = "" }) => {
    return <div className={`text-center mx-auto dark:bg-gray-700 p-2 md:p-4 lg:p-6 ${className}`}>
        {children}
    </div>
}

export default Wrapper;