import * as React from "react";
import IChildrenProps from "props/IChildrenProps";
import IExtensibleClassProps from "props/IExtensibleClassProps";

interface Props extends IChildrenProps, IExtensibleClassProps { }

const List : React.FC<Props> = ({ children, className = "" })  => {
    return <div className="text-left ml-4 md:ml-4 lg:ml-8"><ul className={`list-disc ml-0 md:ml-4 lg:ml-8 ${className}`}>
        {children}
    </ul></div>
}

export default List;